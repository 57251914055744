import React, {Fragment, useEffect, useRef, useState} from "react";
import {makeId} from "../../../utils/makeId";
import Card from "../../../components/ui/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from 'react-bootstrap/Popover';

const popover = (
  <Popover id="popover" className={'popover'}>
    <Popover.Body>
      Press Tab, Space or Right Arrow to <b>move forward.</b><br/>
      Press Shift + Tab or Left Arrow to <b>go back.</b>
    </Popover.Body>
  </Popover>
);


function Translate(props) {
  const [text, setText] = useState('');
  const [continueButtonFocus, setContinueButtonFocus] = useState(false);
  const [inputValue, setInputValue] = useState({});
  const [inputClass, setInputClass] = useState('translate-answer-input translate-initial-input');
  const [loading, setLoading] = useState(false);
  const [correctSolution, setCorrectSolution] = useState([]);
  const [translation, setTranslation] = useState('');
  const [currentCapNo, setCurrentCapNo] = useState(0);
  const [correctWordWidth, setCorrectWordWidth] = useState([]);
  const [inputWordWidth, setInputWordWidth] = useState([]);

  const continueButton = useRef(null);
  const inputLength = useRef(null);
  const correctWordsLength = useRef(null);
  const inputWordsLength = useRef(null);

  // Set on first render
  useEffect(() => {
    localStorage.setItem('responseReceived', 'true');
    setTranslation(props.exerciseData.context_translation);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Set after every API response
  useEffect(() => {
    // Set the correct solution
    let solutionArr = props.exerciseData.solution.trim().split(' ');
    setCorrectSolution(solutionArr);

    // Get the widths of the correct words
    let correctWordsWidth = [];
    if (correctWordsLength.current.children.length > 0) {
      for (let i = 0; i < solutionArr.length; i++) {
        if (solutionArr[i] !== '') {
          try {
            correctWordsWidth.push(correctWordsLength.current.children[i].offsetWidth);
          } catch (e) {
          }
        }
      }
    }
    setCorrectWordWidth(correctWordsWidth);

    if (!props.exerciseData.feedback_full) {
      setInputValue({});
      setCurrentCapNo(0);
    }

    const timer = setTimeout(() => {
      setLoading(false);
      localStorage.setItem('setEnterKeyPressed', '0');
      continueButton.current.disabled = false;

      let feedback = props.exerciseData.feedback_full;
      let correctedInputValue = {};
      for (let x in feedback) {
        correctedInputValue[x] = feedback[x].display;
      }
      setInputValue(correctedInputValue);

      setTranslation(props.exerciseData.context_translation);
      setCurrentCapNo(0);
    }, 1200);
    return () => clearTimeout(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.exerciseData]);

  const growingInput = () => {
    const changeHandler = evt => {
      continueButton.current.disabled = false;
      setInputClass('translate-answer-input translate-initial-input');

      let targetValue = evt.target.value === ' ' ? '  ' : evt.target.value;
      targetValue = targetValue.trim();

      setInputValue({
        ...inputValue,
        [currentCapNo]: targetValue
      });
    };

    const tmpCurrentCapNo = currentCapNo;

    // Set current cap number to 0 if all caps are filled correctly
    let solutionArray = props.exerciseData.solution.trim().split(' ');
    const inputValueArray = Object.values(inputValue);

    let correct = false;
    if (JSON.stringify(solutionArray) === JSON.stringify(inputValueArray)) {
      correct = true;
      setCurrentCapNo(0);
    }


    return solutionArray.map((item, index) => {
      let capNo = inputValue[tmpCurrentCapNo] === solutionArray[tmpCurrentCapNo] ? tmpCurrentCapNo + 1 : tmpCurrentCapNo;

      // Set the current cap input
      if (index === capNo && !correct) {
        let currentClass = item === inputValue[capNo] ? 'translate-answer-input translate-correct-cap' : inputClass;
        setCurrentCapNo(capNo);
        const tmpWidth = inputValue[capNo] ? correctWordWidth[index] + 'px' : 30 + 'px';

        return <span key={index}> <input name="answerInput" id="textbox"
                                         style={{
                                           minWidth: tmpWidth,
                                           width: inputLength.current.offsetWidth + 0 + 'px'
                                         }}
                                         autoComplete="off" autoCorrect="off" autoCapitalize="off"
                                         spellCheck="false" data-word="afraid" data-lpignore="true"
                                         className={currentClass} autoFocus onChange={changeHandler}
                                         value={inputValue[capNo]} type="text"/>
          <span>  </span>
        </span>
      }

      // Set cap outlooks if not current cap
      else {
        let value = inputValue[index] ? inputValue[index] : '       ';
        let capInput = inputValue[index] ? inputValue[index].trim() : '';

        // If answer is wrong and cap is not empty
        if (capInput !== solutionArray[index] && capInput !== '') {
          // Set the cap width
          const tmpLength = inputWordWidth[index] >= correctWordWidth[index] ? inputWordWidth[index] : correctWordWidth[index];

          return <span key={index}>
                 <span className='translate-neutral-cap'
                       style={{width: tmpLength + 'px'}}>{capInput}</span>
                 <span>  </span>
               </span>
        }

        let capClassName = capInput === solutionArray[index] ? 'translate-correct-cap' : 'translate-neutral-cap';

        return <span key={index}>
                 <span className={capClassName}
                       style={{width: 30 + 'px'}}>{value}</span>
                 <span> </span>
               </span>
      }
    })
  };

  const createText = () => {
    let splittedText = props.exerciseData.context_display.split('|_|');
    let mappedText = splittedText.map((item, index) => {
      // Remove all '|' characters from the item
      item = item.replace(/\|/g, '');
      item = item.replace(/\n/g, '');
      item = item.replace(/\r/g, '');

      // Set current cap design
      if (index === 1) {
        return <span key={makeId(5)}>{growingInput()}{item}</span>
      }

      // Set unanswered caps design
      return <span key={makeId(5)}>
        {index !== 0 && <span className={'translate-tasks-gap'}></span>}
        {item}</span>
    });

    setText(mappedText);
  }

  // Set after every input change
  useEffect(() => {
    createText();

    // Get the word widths of the inputs
    let solutionArr = props.exerciseData.solution.trim().split(' ');
    let inputWordsWidth = [];
    if (inputWordsLength.current.children.length > 0) {
      for (let i = 0; i < solutionArr.length; i++) {
        if (solutionArr[i] !== '') {
          try {
            inputWordsWidth.push(inputWordsLength.current.children[i].offsetWidth);
          } catch (e) {
          }
        }
      }
    }
    setInputWordWidth(inputWordsWidth);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputValue, loading, props.exerciseData, currentCapNo]);


  useEffect(() => {
    if (continueButtonFocus) {
      continueButton.current.focus();
    }
  }, [continueButtonFocus]);


  function handleContinue() {
    createText();
    setLoading(true);
    continueButton.current.disabled = false;
    props.getQuestion(props.textName, 'Post translate answer', inputValue);
    setInputClass('translate-answer-input translate-initial-input');
    continueButton.current.disabled = true;
    setContinueButtonFocus(false);
  }

  // If input value changes
  useEffect(() => {
    const listener = event => {
      // If last cap is filled and space is pressed
      if (event.keyCode === 32 && props.exerciseData.gap_lengths[0] === currentCapNo + 1) {
        let lastCapValue = inputValue[currentCapNo];
        lastCapValue = lastCapValue ? lastCapValue.trim() : '';
        let tmpInputValue = inputValue;
        // Replace last cap value with the correct one
        tmpInputValue[currentCapNo] = lastCapValue;
        setInputValue(tmpInputValue);
        event.preventDefault();
      }

      // Space, Tab or right arrow pressed
      if (((event.keyCode === 32 || event.keyCode === 9) || event.keyCode === 39) &&
        props.exerciseData.gap_lengths[0] > currentCapNo + 1) {
        event.preventDefault();
        setCurrentCapNo(currentCapNo + 1);
      }

      // Shift + Tab pressed or left arrow pressed
      if (((event.keyCode === 9 && event.shiftKey) || event.keyCode === 37) && currentCapNo > 0) {
        event.preventDefault();

        let solutionArray = props.exerciseData.solution.trim().split(' ');

        if (inputValue[currentCapNo - 1] === solutionArray[currentCapNo - 1]) {
          setCurrentCapNo(currentCapNo - 2);
        } else {
          setCurrentCapNo(currentCapNo - 1);
        }
      }
    }
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputValue, currentCapNo]);

  useEffect(() => {
    const listener = event => {
      if ((event.code === "Enter" || event.code === "NumpadEnter")
        && (localStorage.getItem('responseReceived') === 'true' || localStorage.getItem('responseReceived') === 'gg_false')) {
        event.preventDefault();
        localStorage.setItem('setEnterKeyPressed', '1');
        localStorage.setItem('responseReceived', 'false');
        handleContinue();
      }
      if (event.code === 9) {
        event.preventDefault();
        setCurrentCapNo(currentCapNo + 1);
        createText();
      }
    }
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputValue]);

  return (<Fragment>
    <div className={'mb-3'}>
      <Card>
        <div>
          <Row>
            <div>
              <div className={'pe-flex-space-between'}>
                <h3 className="holodeck_h3 px-4 pt-4">Translate the missing part</h3>
                <div className="px-4 pt-4">
                  <img src="icons/personalization.svg" alt="Text"/>
                </div>
              </div>
            </div>
          </Row>

          <div className="px-4 pt-1 pb-4">
            Enter the missing words into the sentence.
            <OverlayTrigger trigger={['hover', 'focus']} placement="auto" overlay={popover}>
              <img className={'translate-info'} src="icons/info.svg" alt="Info"/>
            </OverlayTrigger>
          </div>
        </div>
      </Card>
    </div>

    <div className={'mb-3'}>
      <Card>
        <div className={'tasks_boarder'}>
          <h4 className="holodeck_h4 px-4 pt-4 pb-3">{props.textName}</h4>
        </div>
        <div className="task-text px-4 pb-3 pt-4">
          {text}
        </div>
        {/*<div className="px-4 pb-3">*/}
        {/*  <ul className={'translate-no-bullet-list'}>*/}
        {/*    {handleFeedbackDisplay()}*/}
        {/*  </ul>*/}
        {/*</div>*/}
        <div className="px-4 pb-4">
          {translation}
        </div>
      </Card>
    </div>

    <Card>
      <Col sm={12}>
        <div className="tasks_bottom_button pe-flex-end p-4">
          <Button className="pe-start-button cloze_select_outline_dark" variant="dark"
                  onClick={handleContinue} ref={continueButton}>
            {loading ? <span>&nbsp;&nbsp;&nbsp;&nbsp;<i className="fa fa-spinner fa-spin"/></span> : null}
            {loading ? null : 'Continue'}
          </Button>
        </div>
      </Col>
    </Card>

    <span className={'pe-word-length'} ref={inputLength}>
      {inputValue[currentCapNo]}
    </span>

    <div ref={correctWordsLength}>
      {correctSolution.map((value, index) => {
        return (
          <span key={index} className={'pe-correct-word-length'}>
            {value}
          </span>
        )
      })}
    </div>

    <div ref={inputWordsLength}>
      {Object.values(inputValue).map((value, index) => {
        return (
          <span key={index} className={'pe-correct-word-length'}>
            {value}
          </span>
        )
      })}
    </div>
  </Fragment>);
}

export default Translate;