import React, {useContext, useState, Fragment} from 'react';
import '../css/animate.css';

import ClozeExercise from "./cloze/ClozeExercise";
import ClozeMenu from "./cloze/ClozeMenu";
import {BACKEND_URL} from "../App";
import AuthContext from "../store/auth-context";
import BookList from "../components/clozeBooks/BookList";
import ClozeSelect from "./cloze/ClozeSelect";
import ParallelReading from "./cloze/ParallelReading";


function Cloze() {
  document.body.style.backgroundColor = "#EEF0F4";
  document.body.style.overflow = 'auto';
  const authCtx = useContext(AuthContext);

  let currentUser = authCtx.user;
  let currentGoogleId = authCtx.googleId;
  if (!currentUser) {
    currentUser = 'no_user_data'
  }

  const [pageDisplayed, setPageDisplayed] = useState('menu');
  const [textData, setTextData] = useState('');
  const [userBookList, setUserBookList] = useState([]);
  const [sefrList, setSefrList] = useState([]);
  const [selectedTitle, setSelectedTitle] = useState('');
  const [selectedAuthor, setSelectedAuthor] = useState('');
  const [selectedImage, setSelectedImage] = useState('');
  const [selectedCefr, setSelectedCefr] = useState('');
  const [selector, setSelector] = useState('task');
  const [language, setLanguage] = useState('');
  const [destLanguage, setDestLanguage] = useState('');
  const [fromUploadTask, setFromUploadTask] = useState(false);
  const [textFileName, setTextFileName] = useState('');
  const [receivedTask, SetReceivedTask] = useState(null);

  const user = {'name': currentUser, 'id': currentGoogleId};

  // Create task for user text
  function createTask(text, taskNo, isNext, text_file_name, from, no_of_text_parts) {
    fetch(BACKEND_URL + '/api/guess_api/create_task', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        text: text,
        text_part_number: taskNo,
        text_file_name: text_file_name,
        is_next: isNext,
        is_first: false,
        no_of_text_parts: no_of_text_parts
      })
    }).then(response => response.json())
      .then(data => {
        setTextData(data);
        setPageDisplayed(from);
        try {
          document.getElementById("next_button").blur();
        } catch (err) {
        }
      });
  }

  function getTask(title, task_data, is_next, from, text_difficulty, task_type, translation_language) {
    if (task_type === 'uploadedTaskAny') {
      setDestLanguage(translation_language);
      setTextData(task_data);
      setPageDisplayed('exercise');
      setUserBookList([]);
      try {
        document.getElementById("next_button").blur();
      } catch (err) {
      }
    } else {
      // Get item from server
      fetch(BACKEND_URL + '/api/guess_api/get_cloze_cap_text', {
        method: 'POST', body: JSON.stringify({
          user: user,
          title: title,
          is_next: is_next,
          task_type: task_type,
          text_difficulty: 'hard',
          chapter_number: task_data.chapter_number,
          text_part_number: task_data.text_part_number,
          serf_list: sefrList,
          language: language,
          from: from,
          translation_language: translation_language
        }), headers: {
          'Content-type': 'application/json; charset=UTF-8'
        },
      })
        .then(function (response) {
            if (response.status !== 200) {
              console.log('Looks like there was a problem. Status Code: ' + response.status);
              return;
            }
            response.json().then(function (data) {
              setTextData(data);
              setPageDisplayed(from);
              setUserBookList([]);
              try {
                document.getElementById("next_button").blur();
              } catch (err) {
              }
            });
          }
        )
        .catch(function (err) {
          console.log('Fetch Error :-S', err);
        });
    }
  }

  function getBooks(selectedSefr, selectedLanguage) {
    if (selectedSefr.length > 0) {
      // Get item from server
      fetch(BACKEND_URL + '/api/guess_api/get_cloze_books', {
        method: 'POST', body: JSON.stringify({
          user: user,
          selectedSefr: selectedSefr,
          selectedLanguage: selectedLanguage
        }), headers: {
          'Content-type': 'application/json; charset=UTF-8'
        },
      })
        .then(function (response) {
            if (response.status !== 200) {
              console.log('Looks like there was a problem. Status Code: ' + response.status);
              return;
            }
            response.json().then(function (data) {
              setUserBookList(data);
              setPageDisplayed('menu');
            });
          }
        )
        .catch(function (err) {
          console.log('Fetch Error :-S', err);
        });
    } else {
      setUserBookList([])
    }
  }

  // Handle input submit
  function submitHandler(selectedSefr, selectedLanguage) {
    getBooks(selectedSefr, selectedLanguage);
    setSefrList(selectedSefr);
    setLanguage(selectedLanguage)
  }

  function goToSelectionOptions(book_data, sefrList) {
    setPageDisplayed('select');
    setSelectedTitle(JSON.parse(book_data).title);
    setSelectedAuthor(JSON.parse(book_data).author);
    setSelectedCefr(JSON.parse(book_data).cefr);
    setSelectedImage(JSON.parse(book_data).image);
    setSefrList(sefrList)
  }

  function getCreatedTask(task_data, text_part_number, no_of_text_parts) {
    const data = {
      chapter_number: 0,
      cloze_text: task_data.cloze_text,
      correct: task_data.correct,
      from_lang: "en",
      hints: "[]",
      img: "./images/books/book_cover.jpg",
      lang_dest: "unknown",
      no_of_text_parts: no_of_text_parts,
      source: "uploaded_by_user",
      task_type: "any",
      text_difficulty: "easy",
      text_part_number: text_part_number
    }
    setTextData(data);
    setPageDisplayed('exercise');
    setUserBookList([]);
    try {
      document.getElementById("next_button").blur();
    } catch (err) {
    }
  }

  function handlePrevious(from_selector, sefrList, taskType, fromUploadTask) {
    if (fromUploadTask) {
      setPageDisplayed('menu');
      setFromUploadTask(false);
    } else {
      if (from_selector === 'task') {
        getBooks(sefrList);
      } else if (from_selector === 'languageSelector') {
        setPageDisplayed('select');
        setSelector('task')
      } else if (from_selector === 'languageSelector') {
        setPageDisplayed('select');
        if (taskType === 'parallel') {
          setSelector('task')
        } else {
          setSelector('languageSelector')
        }
      }
    }
  }

  function sSelector(from) {
    setSelector(from);
  }

  function handleCancel(from_selector, fromUploadTask) {
    if (fromUploadTask) {
      setFromUploadTask(false);
    }
    if (from_selector === 'task') {
      setPageDisplayed('menu');
    } else if (from_selector === 'difficulty') {
      setPageDisplayed('menu');
      setSelector('task')
    } else if (from_selector === 'languageSelector') {
      setPageDisplayed('menu');
    }
    setUserBookList([])
  }

  const dataFromExercize = (from, source) => {
    if (source === 'uploaded_by_user') {
      setFromUploadTask(false);
    }
    setSelector('task');
    setPageDisplayed('menu');
    setUserBookList([])
  };

  const uploadTaskHandler = (firstData) => {
    SetReceivedTask(firstData);
    setTextFileName(firstData.text_file_name);
    setPageDisplayed('select');
    setFromUploadTask(true);
  };

  // Handle page navigation
  if (pageDisplayed === 'menu') {
    return <Fragment>
      <ClozeMenu submitHandler={submitHandler} uploadTaskHandler={uploadTaskHandler}/>
      <BookList getBookTitle={goToSelectionOptions} sefrList={sefrList} books={userBookList}/>
    </Fragment>;
  }

  if (pageDisplayed === 'select') {
    return <Fragment>
      <ClozeSelect handleCancel={handleCancel} handlePrevious={handlePrevious} selectedTitle={selectedTitle}
                   selectedAuthor={selectedAuthor} selectedCefr={selectedCefr} getTask={getTask} selector={selector}
                   sSelector={sSelector} sefrList={sefrList} dataFromExercize={dataFromExercize} language={language}
                   selectedImage={selectedImage} fromUploadTask={fromUploadTask} textFileName={textFileName}
                   receivedTask={receivedTask}/>
    </Fragment>;
  }

  if (pageDisplayed === 'exercise') {
    return <Fragment>
      <ClozeExercise dataFromExercize={dataFromExercize} textData={textData} getTask={getTask}
                     destLanguage={destLanguage} uploadTaskHandler={uploadTaskHandler} createTask={createTask}
                     getCreatedTask={getCreatedTask}/>
    </Fragment>;
  }

  if (pageDisplayed === 'parallel') {
    return <Fragment>
      <ParallelReading dataFromExercize={dataFromExercize} textData={textData} getTask={getTask}/>
    </Fragment>;
  }
}

export default Cloze;