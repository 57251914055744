import React, {useEffect, useState} from 'react';
import {FaSquare, FaCheckSquare, FaMinusSquare} from "react-icons/fa";
import {IoMdArrowDropright} from "react-icons/io";
import TreeView, {flattenTree} from "react-accessible-treeview";
import cx from "classnames";
import "./Tree.css";
import {BACKEND_URL} from "../../App";

const folder = {
  name: "",
  children: [],
};

const ini_data = flattenTree(folder);


function MultiSelectCheckbox() {

  // On first render
  useEffect(() => {
    getTreeView();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [tree_data, setTreeData] = useState(ini_data);
  const [loading, setLoading] = useState(true); // Loading state

  //Get treeview from backend
  function getTreeView() {
    fetch(BACKEND_URL + '/api/events_api/get_events_tree', {
      method: 'POST', body: JSON.stringify({
        user: 'user1'
      }), headers: {
        'Content-type': 'application/json; charset=UTF-8'
      },
    })
      .then(function (response) {
          if (response.status !== 200) {
            setLoading(false);
            console.log('Looks like there was a problem. Status Code: ' + response.status);
            return;
          }
          // Examine the text in the response
          response.json().then(function (data) {
            const tree = flattenTree(data);
            setTreeData(tree);
            setLoading(false);
          });
        }
      )
      .catch(function (err) {
        setLoading(false);
        console.log('Fetch Error :-S', err);
      });
  }


  const getSelectedParameters = (nodes) => {
    console.log(nodes)
  };

  if (loading) {
    return <p>Loading...</p>; // Render this while loading
  } else {

    return (
      <div>
        <div className="checkbox">
          <TreeView
            data={tree_data}
            aria-label="Checkbox tree"
            multiSelect
            propagateSelect
            propagateSelectUpwards
            togglableSelect
            nodeRenderer={({
                             element,
                             isBranch,
                             isExpanded,
                             isSelected,
                             isHalfSelected,
                             getNodeProps,
                             level,
                             handleSelect,
                             handleExpand,
                           }) => {
              return (
                <div
                  {...getNodeProps({onClick: handleExpand})}
                  style={{marginLeft: 20 * (level - 1)}}
                >
                  {isBranch && <ArrowIcon isOpen={isExpanded}/>}
                  <CheckBoxIcon
                    className="checkbox-icon"
                    style={{color: isSelected ? "#85c7a0" : "#dcdcdc"}}
                    onClick={(e) => {
                      handleSelect(e);
                      getSelectedParameters(element)
                      e.stopPropagation();
                    }}
                    variant={
                      isHalfSelected ? "some" : isSelected ? "all" : "none"
                    }
                  />
                  <span className="name">{element.name}</span>
                </div>
              );
            }}
          />
        </div>
      </div>
    );
  }
}

const ArrowIcon = ({isOpen, className}) => {
  const baseClass = "arrow";
  const classes = cx(
    baseClass,
    {[`${baseClass}--closed`]: !isOpen},
    {[`${baseClass}--open`]: isOpen},
    className
  );
  return <IoMdArrowDropright style={{marginLeft: -16}} className={classes}/>;
};

const CheckBoxIcon = ({variant, ...rest}) => {
  switch (variant) {
    case "all":
      return <FaCheckSquare {...rest} />;
    case "none":
      return <FaSquare {...rest} />;
    case "some":
      return <FaMinusSquare {...rest} />;
    default:
      return null;
  }
};


export default MultiSelectCheckbox;