import React, {useState, useEffect} from 'react';

import ModalCard from "./ModalCard";
import {Button, Form} from "react-bootstrap";
import classes from './Modal.module.css';

const Modal = (props) => {
  const [enteredExerciseName, setEnteredExerciseName] = useState('');
  const [placeholder, setPlaceholder] = useState('Text name');

  useEffect(() => {
    setEnteredExerciseName('');
    setPlaceholder('');
  }, [props.placeholder]);

  const exerciseNameChangeHandler = (event) => {
    setEnteredExerciseName(event.target.value);
  };

  return (
    <div>
      <form id="modal" onSubmit={e => props.onSubmit(e, enteredExerciseName)}>
        <div className={classes.backdrop}/>
        <ModalCard className={classes.modal}>
          <header className={classes.header}>
            <h3>{props.title}</h3>
          </header>

          <div className={classes.content}>
            <p>{props.message}</p>
            <Form.Control id="exerciseName"
                          type="text"
                          value={enteredExerciseName}
                          onChange={exerciseNameChangeHandler}
                          autoFocus={true}
                          placeholder={placeholder}/>

            {/*Error message*/}
            {props.error && (
              <div className={classes.error}>
                <p>{props.error}</p>
              </div>
            )}
          </div>
          <footer className={classes.actions}>
            <Button className="holo_dark_button" variant="dark" type="submit">Okay</Button>
          </footer>
        </ModalCard>
      </form>
    </div>
  );
};

export default Modal;
