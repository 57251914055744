import React from 'react';
import {useTable, useSortBy, useFilters} from 'react-table';
import {Table} from 'reactstrap';
import {Filter, DefaultColumnFilter} from './filters';
import './TableContainer.css';

const TableContainer = ({columns, data}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
      defaultColumn: {Filter: DefaultColumnFilter},
    },
    useFilters,
    useSortBy
  );

  const generateSortingIndicator = (column) => {
    const UpArrow = () => <i className="fa fa-caret-up arrows"></i>;
    const DownArrow = () => <i className="fa fa-caret-down arrows"></i>;

    return column.isSorted ? (column.isSortedDesc ? UpArrow() : DownArrow()) : '';
  };

  return (
    <Table bordered hover {...getTableProps()}>
      <thead className='noselect'>
      {headerGroups.map((headerGroup) => (
        <tr {...headerGroup.getHeaderGroupProps()}>
          {headerGroup.headers.map((column) => (
            <th {...column.getHeaderProps()}>
              <div {...column.getSortByToggleProps()}>
                {column.render('Header')}
                {generateSortingIndicator(column)}
              </div>
              <Filter column={column}/>
            </th>
          ))}
        </tr>
      ))}
      </thead>

      <tbody {...getTableBodyProps()}>
      {rows.map((row) => {
        prepareRow(row);
        return (
          <tr {...row.getRowProps()}>
            {row.cells.map((cell) => {
              return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>;
            })}
          </tr>
        );
      })}
      </tbody>
    </Table>
  );
};

export default TableContainer;