export const languageList = (value) => {
  const options = [{'name': 'Afrikaans (Afrikaans)', 'value': 'af'}, {
    'name': 'Albanian (Shqip)',
    'value': 'sq'
  }, {'name': 'Amharic (አማርኛ)', 'value': 'am'}, {'name': 'Arabic (عربي)', 'value': 'ar'}, {
    'name': 'Armenian (Հայերեն)',
    'value': 'hy'
  }, {'name': 'Azerbaijani (Azərbaycanlı)', 'value': 'az'}, {
    'name': 'Basque (Euskalduna)',
    'value': 'eu'
  }, {'name': 'Belarusian (Беларуская)', 'value': 'be'}, {
    'name': 'Bengali (বাংলা)',
    'value': 'bn'
  }, {'name': 'Bosnian (Bosanski)', 'value': 'bs'}, {
    'name': 'Bulgarian (Български)',
    'value': 'bg'
  }, {'name': 'Catalan (Català)', 'value': 'ca'}, {
    'name': 'Cebuano (Cebuano)',
    'value': 'ceb'
  }, {'name': 'Chichewa (Chichewa)', 'value': 'ny'}, {
    'name': 'Chinese (simplified) (简体中文）)',
    'value': 'zh-cn'
  }, {'name': 'Chinese (traditional) (中國傳統的）)', 'value': 'zh-tw'}, {
    'name': 'Corsican (Corsu)',
    'value': 'co'
  }, {'name': 'Croatian (Hrvatski)', 'value': 'hr'}, {
    'name': 'Czech (Čeština)',
    'value': 'cs'
  }, {'name': 'Danish (Dansk)', 'value': 'da'}, {
    'name': 'Dutch (Nederlands)',
    'value': 'nl'
  }, {'name': 'English (English)', 'value': 'en'}, {
    'name': 'Esperanto (Esperanto)',
    'value': 'eo'
  }, {'name': 'Estonian (Eesti keel)', 'value': 'et'}, {
    'name': 'Filipino (Pilipino)',
    'value': 'tl'
  }, {'name': 'Finnish (Suomalainen)', 'value': 'fi'}, {
    'name': 'French (Français)',
    'value': 'fr'
  }, {'name': 'Frisian (Frysk)', 'value': 'fy'}, {
    'name': 'Galician (Galego)',
    'value': 'gl'
  }, {'name': 'Georgian (ქართველი)', 'value': 'ka'}, {
    'name': 'German (Deutsch)',
    'value': 'de'
  }, {'name': 'Greek (Ελληνικά)', 'value': 'el'}, {
    'name': 'Gujarati (ગુજરાતી)',
    'value': 'gu'
  }, {'name': 'Haitian creole (Kreyol ayisyen)', 'value': 'ht'}, {
    'name': 'Hausa (Hausa)',
    'value': 'ha'
  }, {'name': 'Hawaiian (Ōlelo hawaiʻi)', 'value': 'haw'}, {
    'name': 'Hebrew (עִברִית)',
    'value': 'iw'
  }, {'name': 'Hebrew (עִברִית)', 'value': 'he'}, {'name': 'Hindi (हिन्दी)', 'value': 'hi'}, {
    'name': 'Hmong (Hmoob)',
    'value': 'hmn'
  }, {'name': 'Hungarian (Magyar)', 'value': 'hu'}, {
    'name': 'Icelandic (Íslensku)',
    'value': 'is'
  }, {'name': 'Igbo (Igbo)', 'value': 'ig'}, {
    'name': 'Indonesian (Bahasa indonesia)',
    'value': 'id'
  }, {'name': 'Irish (Gaeilge)', 'value': 'ga'}, {
    'name': 'Italian (Italiano)',
    'value': 'it'
  }, {'name': 'Japanese (日本)', 'value': 'ja'}, {
    'name': 'Javanese (Basa jawa)',
    'value': 'jw'
  }, {'name': 'Kannada (ಕನ್ನಡ)', 'value': 'kn'}, {'name': 'Kazakh (Қазақ)', 'value': 'kk'}, {
    'name': 'Khmer (ខ្មែរ)',
    'value': 'km'
  }, {'name': 'Korean (한국어)', 'value': 'ko'}, {
    'name': 'Kurdish (kurmanji) (Kurdî (kurmancî))',
    'value': 'ku'
  }, {'name': 'Kyrgyz (Кыргызча)', 'value': 'ky'}, {'name': 'Lao (ລາວ)', 'value': 'lo'}, {
    'name': 'Latin (Latin)',
    'value': 'la'
  }, {'name': 'Latvian (Latviski)', 'value': 'lv'}, {
    'name': 'Lithuanian (Lietuvis)',
    'value': 'lt'
  }, {'name': 'Luxembourgish (Lëtzebuergesch)', 'value': 'lb'}, {
    'name': 'Macedonian (Македонски)',
    'value': 'mk'
  }, {'name': 'Malagasy (Malagasy)', 'value': 'mg'}, {
    'name': 'Malay (Melayu)',
    'value': 'ms'
  }, {'name': 'Malayalam (മലയാളം)', 'value': 'ml'}, {
    'name': 'Maltese (Malti)',
    'value': 'mt'
  }, {'name': 'Maori (Maori)', 'value': 'mi'}, {
    'name': 'Marathi (मराठी)',
    'value': 'mr'
  }, {'name': 'Mongolian (Монгол)', 'value': 'mn'}, {
    'name': 'Myanmar (burmese) (မြန်မာ (ဗမာ))',
    'value': 'my'
  }, {'name': 'Nepali (नेपाली)', 'value': 'ne'}, {'name': 'Norwegian (Norsk)', 'value': 'no'}, {
    'name': 'Odia (ଓଡିଆ)',
    'value': 'or'
  }, {'name': 'Pashto (پښتو)', 'value': 'ps'}, {'name': 'Persian (فارسی)', 'value': 'fa'}, {
    'name': 'Polish (Polski)',
    'value': 'pl'
  }, {'name': 'Portuguese (Português)', 'value': 'pt'}, {
    'name': 'Punjabi (ਪੰਜਾਬੀ)',
    'value': 'pa'
  }, {'name': 'Romanian (Română)', 'value': 'ro'}, {
    'name': 'Russian (Русский)',
    'value': 'ru'
  }, {'name': 'Samoan (Samoa)', 'value': 'sm'}, {
    'name': 'Scots gaelic (Gàidhlig na h-alba)',
    'value': 'gd'
  }, {'name': 'Serbian (Српски)', 'value': 'sr'}, {
    'name': 'Sesotho (Sesotho)',
    'value': 'st'
  }, {'name': 'Shona (Shona)', 'value': 'sn'}, {'name': 'Sindhi (سنڌي)', 'value': 'sd'}, {
    'name': 'Sinhala (සිංහල)',
    'value': 'si'
  }, {'name': 'Slovak (Slovenský)', 'value': 'sk'}, {
    'name': 'Slovenian (Slovenščina)',
    'value': 'sl'
  }, {'name': 'Somali (Somaliyeed)', 'value': 'so'}, {
    'name': 'Spanish (Español)',
    'value': 'es'
  }, {'name': 'Sundanese (Basa sunda)', 'value': 'su'}, {
    'name': 'Swahili (Kiswahili)',
    'value': 'sw'
  }, {'name': 'Swedish (Svenska)', 'value': 'sv'}, {'name': 'Tajik (Тоҷикӣ)', 'value': 'tg'}, {
    'name': 'Tamil (தமிழ்)',
    'value': 'ta'
  }, {'name': 'Telugu (తెలుగు)', 'value': 'te'}, {'name': 'Thai (ไทย)', 'value': 'th'}, {
    'name': 'Turkish (Türk)',
    'value': 'tr'
  }, {'name': 'Ukrainian (Український)', 'value': 'uk'}, {
    'name': 'Urdu (اردو)',
    'value': 'ur'
  }, {'name': 'Uyghur (Uyghur)', 'value': 'ug'}, {
    'name': "Uzbek (O'zbek)",
    'value': 'uz'
  }, {'name': 'Vietnamese (Tiếng việt)', 'value': 'vi'}, {
    'name': 'Welsh (Cymraeg)',
    'value': 'cy'
  }, {'name': 'Xhosa (Isixhosa)', 'value': 'xh'}, {
    'name': 'Yiddish (ייִדיש)',
    'value': 'yi'
  }, {'name': 'Yoruba (Yoruba)', 'value': 'yo'}, {'name': 'Zulu (Zulu)', 'value': 'zu'}]

  if (value) {
    for (var i in options) {
      if (options[i].value === value) {
        return options[i].name
      }
    }
  } else {
    return options
  }
}

