import React, {useState, useEffect, useCallback, useMemo, Fragment, useRef} from 'react';
import Row from "react-bootstrap/Row";

import './ClozeMenu.css'
import Col from "react-bootstrap/Col";
import Frame from "../../components/ui/Frame";
import Card from "../../components/ui/Card";
import {useDropzone} from 'react-dropzone'
import {BACKEND_URL} from "../../App";

const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '16px',
  borderWidth: 2,
  borderRadius: 8,
  borderColor: '#B2B8CB',
  borderStyle: 'dashed',
  backgroundColor: '#EEF0F4',
  color: '#2C3143',
  outline: 'none',
  transition: 'border .24s ease-in-out'
};

const focusedStyle = {
  borderColor: '#6CD5E5'
};

const acceptStyle = {
  borderColor: '#43D281',
  backgroundColor: '#f4f5f8',
};

const rejectStyle = {
  borderColor: '#FE5C55'
};


function ClozeMenu(props) {
  const [selectedSefr, setSelectedSefr] = useState([]);
  const [change, setChange] = useState(0);
  const [languageIsSelected, setLanguageIsSelected] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState();
  const [reject, setReject] = useState(false);
  const [fileTypeWarning, setFileTypeWarning] = useState('cloze_booklist_list_hide');
  const [fileCountWarning, setFileCountWarning] = useState('cloze_booklist_list_hide');
  const [fileSizeWarning, setFileSizeWarning] = useState('cloze_booklist_list_hide');
  const [languageWarning, setLanguageWarning] = useState('cloze_booklist_list_hide');
  const [taskLoading, setTaskLoading] = useState(false);
  const [uploadClass, setUploadClass] = useState('');
  const [chooseLanguageClass, setChooseLanguageClass] = useState('');
  const [uploadTextClass, setUploadTextClass] = useState('cloze_menu_select_header text-center');

  const cerfClassName = "col-1 text-center cloze_cefr_button";

  useEffect(() => {
    props.submitHandler(selectedSefr, selectedLanguage)
    // eslint-disable-next-line
  }, [change]);

  function handleCerf(event) {
    const cefr_id = event.target.id;
    const tar = event.target;
    let id_index = selectedSefr.indexOf(cefr_id);

    if (tar.className === 'col-1 text-center cloze_cefr_button_active') {
      tar.className = 'col-1 text-center cloze_cefr_button';
      tar.getElementsByTagName("span")[0].style.backgroundColor = "#FFFFFF";
      tar.getElementsByTagName("img")[0].style.display = "none";

      if (id_index !== -1) {
        selectedSefr.splice(id_index, 1);
        setSelectedSefr(selectedSefr);
        setChange(change + 1)
      }
    } else {
      tar.className = 'col-1 text-center cloze_cefr_button_active';
      tar.getElementsByTagName("span")[0].style.backgroundColor = "#EEF0F4";
      tar.getElementsByTagName("img")[0].style.display = "block";
      if (id_index === -1) {
        setSelectedSefr([...selectedSefr, cefr_id]);
        setChange(change - 1)
      }
    }
  }

  const engFlag = useRef(null);
  const gerFlag = useRef(null);

  function handleLanguage(event) {
    setUploadClass('cloze_hide_input');
    setLanguageIsSelected(true);
    const language = event.target.id;
    setSelectedLanguage(language);

    if (language === 'eng') {
      gerFlag.current.className = 'cloze_cefr_flag_not_selected';
      engFlag.current.className = 'cloze_cefr_flag_selected';
      props.submitHandler(selectedSefr, 'eng')
      return
    }

    if (language === 'ger') {
      engFlag.current.className = 'cloze_cefr_flag_not_selected';
      gerFlag.current.className = 'cloze_cefr_flag_selected';
      props.submitHandler(selectedSefr, 'ger')
    }
  }

  const ch_img = <img className="cloze_menu_check" src="icons/checkmark_vector.svg" alt="Checked"/>

  // Run Web Worker for creating tasks
  function runWebWorker(text, index) {
    const worker = new Worker("/task.worker.js", {type: "module"});

    // Send next task to worker
    const postMessageData = {'index': index, 'text': text};
    worker.postMessage(postMessageData);

    worker.onmessage = function (e) {
      if (sessionStorage.getItem('tasks') !== null) {
        let tasks = JSON.parse(sessionStorage.getItem('tasks'));
        tasks[index] = e.data;
        sessionStorage.setItem('tasks', JSON.stringify(tasks));
      } else {
        let tasks = {};
        tasks[index] = e.data;
        sessionStorage.setItem('tasks', JSON.stringify(tasks));
      }
    };
  }

  // Create first task and split text into tasks
  function createFirstTask(formData) {
    setTaskLoading(true);
    setChooseLanguageClass('cloze_hide_input');
    setUploadTextClass('cloze_hide_input');
    fetch(BACKEND_URL + '/api/guess_api/create_task', {
      method: 'POST',
      body: formData
    }).then(response => response.json())
      .then(data => {
        if (data.error) {
          //Language check
          console.log(data.error);
          setTaskLoading(false);
          setUploadTextClass('cloze_menu_select_header text-center');
          setLanguageWarning('cloze_booklist_warning');
          setReject(true);
          setTimeout(function () {
            setReject(false);
            setLanguageWarning('cloze_booklist_list_hide');
          }, 5000);
        } else {
          // Store text data for the user session
          if (typeof (Storage) !== "undefined") {
            sessionStorage.splitted_text = JSON.stringify(data.splitted_text);
            sessionStorage.tasks = JSON.stringify({0:{'cloze_text':'', 'correct':[]}});

            // Creat next tasks
            const taskToCreate = 1;
            data.splitted_text.forEach(createTask);

            function createTask(text, index) {
              if (index < taskToCreate + 1 && index > 0) {
                runWebWorker(text, index);
              }
            }

          } else {
            alert('Sorry! No Web Storage support..')
          }

          props.uploadTaskHandler(data);
        }
      });
  }

  // Drag and drop settings
  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      const fileType = file.name.split('.').pop();
      const allowedFileTypes = ['txt', 'epub'];
      const validFileType = allowedFileTypes.includes(fileType);
      const validFileSize = file.size < 10000000;
      const validFileCount = acceptedFiles.length === 1;

      if (!validFileType || !validFileCount || !validFileSize) {
        setReject(true);
        !validFileType && setFileTypeWarning('cloze_booklist_warning');
        !validFileCount && setFileCountWarning('cloze_booklist_warning');
        !validFileSize && setFileSizeWarning('cloze_booklist_warning');
        setTimeout(function () {
          setReject(false);
          setFileTypeWarning('cloze_booklist_list_hide');
          setFileCountWarning('cloze_booklist_list_hide');
          setFileSizeWarning('cloze_booklist_list_hide');
          setLanguageWarning('cloze_booklist_list_hide');
        }, 5000);
        return
      }

      const reader = new FileReader();
      reader.onabort = () => console.log('file reading was aborted');
      reader.onerror = () => console.log('file reading has failed');
      reader.onload = () => {
        const formData = new FormData();
        formData.append("file", file);
        createFirstTask(formData);
      }
      reader.readAsArrayBuffer(file)
    })
    // eslint-disable-next-line
  }, [])

  const {
    getRootProps, getInputProps, isFocused, isDragAccept
  } = useDropzone({onDrop});

  const style = useMemo(() => ({
    ...baseStyle, ...(isFocused ? focusedStyle : {}), ...(isDragAccept ? acceptStyle : {}), ...(reject ? rejectStyle : {})
  }), [isFocused, isDragAccept, reject]);

  return <Frame>
    <h3 className="holodeck_h3 px-4">Reading and fill in the blank exercises</h3>
    {!languageIsSelected && <Card>
      <Col fluid="md" className="px-3 mb-4">
        <Row className="cloze_menu_top_header">
          <Col className="cloze_menu_fixed_one">
            <span>Practice your language skills with texts and books that are right for your level.<br/><br/>
            Select texts based on your CEFR level and use our parallel reading mode to work through them.<br/><br/>
            You can also practice your contextual understanding by completing exercises that ask you to fill in the
            correct word or focus in on verbs specifically. Here you will be asked to enter the correct tense or
            conjugation based on the context.<br/><br/>
              Can't find a text you are interested in? You can upload your own text.</span>
          </Col>
          <Col/>
        </Row>
      </Col>
    </Card>}

    <Card>
      <Col fluid="md" className="px-3 mb-4">
        <div className={chooseLanguageClass}>
          <Row className="cloze_menu_select_header text-center">
            <h3 className="holodeck_h4">&nbsp;&nbsp;Choose a language for your book from our selection or</h3>
          </Row>

          <Row className="cloze_menu_select_language d-flex justify-content-center">
            <div id="eng" className="col-1 text-center cloze_cefr_flag" onClick={handleLanguage} ref={engFlag}><img
              className="cloze_select_check"
              src="images/english.svg"
              alt="English"/></div>
            <div id="ger" className="col-1 text-center cloze_cefr_flag" onClick={handleLanguage} ref={gerFlag}><img
              className="cloze_select_check"
              src="images/german.svg"
              alt="German"/></div>
          </Row>
        </div>
        <div className={uploadClass}>
          <Row className={uploadTextClass}>
            <h4 className="holodeck_h4">&nbsp;&nbsp;Upload your own text</h4>
          </Row>
          <Row className="mx-3 mt-1 mb-4">
            {taskLoading ? <Row>
              <div className="text-center mt-3">
                <h4 className="holodeck_h4">Please wait, loading the text and creating the task.</h4>
              </div>
              <div className="text-center py-5">
                <img src="icons/task_preloader.svg" alt="Task preloader"/>
              </div>
            </Row> : <div className="mb-4">
              <div  {...getRootProps({
                style,
              })}>
                <input {...getInputProps()} />
                <p>Drag and drop book file here, or click to select files.</p>
                <p className={"cloze_booklist_text_file"}><img src="icons/text_file.svg" alt="Text file"/> (TXT, EPUB)
                </p>
                <p className={fileTypeWarning}>Please select the appropriate file type.</p>
                <p className={fileCountWarning}>Please select only one file.</p>
                <p className={fileSizeWarning}>Please select a file less than 10 MB.</p>
                <p className={languageWarning}>The text is not in English, please select an English test.</p>
              </div>
            </div>}
          </Row>
        </div>
      </Col>
    </Card>

    {languageIsSelected && <Fragment>
      <Card>
        <Col fluid="md" className="px-3 mb-4">
          <Row className="cloze_menu_select_header d-flex justify-content-center text-center">
            <h3 className="holodeck_h4">Select one or more CEFR difficulty levels</h3>
          </Row>

          <Row className="cloze_menu_select_buttons d-flex justify-content-center">
            <div id="A1" className={cerfClassName} onClick={handleCerf}>{ch_img}A1<span
              className="cloze_menu_count">{(selectedLanguage === 'eng') ? 0 : 2}</span></div>
            <div id="A2" className={cerfClassName} onClick={handleCerf}>{ch_img}A2<span
              className="cloze_menu_count">{(selectedLanguage === 'eng') ? 7 : 7}</span></div>
            <div id="B1" className={cerfClassName} onClick={handleCerf}>{ch_img}B1<span
              className="cloze_menu_count">{(selectedLanguage === 'eng') ? 2 : 0}</span></div>
            <div id="B2" className={cerfClassName} onClick={handleCerf}>{ch_img}B2<span
              className="cloze_menu_count">{(selectedLanguage === 'eng') ? 9 : 1}</span></div>
            <div id="C1" className={cerfClassName} onClick={handleCerf}>{ch_img}C1<span
              className="cloze_menu_count">{(selectedLanguage === 'eng') ? 10 : 0}</span></div>
            <div id="C2" className={cerfClassName} onClick={handleCerf}>{ch_img}C2<span
              className="cloze_menu_count">{(selectedLanguage === 'eng') ? 43 : 1}</span></div>
          </Row>
        </Col>
      </Card>
    </Fragment>}
  </Frame>;
}

export default ClozeMenu;