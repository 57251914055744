import React, {Fragment} from 'react';
import './Events.css';
import Frame from "../../components/ui/Frame";
import Card from "../../components/ui/Card";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Tree from "../../components/events/Tree";

import 'bootstrap/dist/css/bootstrap.min.css';


function Events() {
  document.body.style.backgroundColor = "#EEF0F4";
  document.body.style.overflow = 'auto';


  return (<Fragment>
    <Frame>
      <h3 className="holodeck_h3 px-4 pb-1">Raw event query</h3>
      <Card>
        <Row className="p-4">
          <Col>
            <div>
              <Tree/>
            </div>
          </Col>
        </Row>
      </Card>
    </Frame>
  </Fragment>);
}

export default Events;