export const refreshTokenSetup = (res, authCtx) => {
  // Timing to renew access token 5 sec before
  let refreshTiming = (res || 3600 - 5 * 60) * 1000;

  const refreshToken = async () => {

    const newAuthRes = await res.reloadAuthResponse();

    refreshTiming = (newAuthRes.expires_in || 3600 - 5 * 60) * 1000;
    authCtx.login(newAuthRes.id_token, localStorage.getItem('user'));

    // Setup the other timer after the first one
    setTimeout(refreshToken, refreshTiming);
  };

  // Setup first refresh timer
  setTimeout(refreshToken, refreshTiming);
};
