import Card from "../ui/Card";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import LineTwo from "../ui/LineTwo";
import {Button} from "react-bootstrap";
import React, {useEffect, useState, useRef} from "react";
import SelectSearch, {fuzzySearch} from "react-select-search";
import '../../css/search.css'
import {languageList} from "../../utils/languages";

function LanguageSelector(props) {
  const [inputValue, setInptValue] = useState('');
  const [options, setOptions] = useState([]);
  const [hideButton, setHideButton] = useState({visibility: 'hidden'});

  const inputDiv = useRef(null);

  useEffect(() => {
    setOptions(e => languageList(''))
  }, [props.fromLang]);

  function renderValue(e) {
    setInptValue(e);
    setHideButton({visibility: 'visible'});
  }

  function renderLanguage(props, option, snapshot, className) {
    return (
      <button {...props} className={className} type="button">
        <span>{option.name}</span>
      </button>
    );
  }

  const rightDiv = useRef(null);

  useEffect(() => {
    rightDiv.current.style.height = 'auto';

    const lHeight = props.leftDivHeight;
    const rHeight = rightDiv.current.offsetHeight;

    if (lHeight > rHeight) {
      rightDiv.current.style.height = lHeight + 'px';
    } else {
      props.leftDivH(rHeight)
    }
  }, [props]);


  return <Col sm={8}>
    <Card>
      <Row className="p-3" ref={rightDiv}>
        <div className="me-auto">
          <h4 className="holodeck_h4 mb-3">Select a translation language</h4>
          <LineTwo/>
        </div>

        <Row className="cloze_select_row_2">
          <form id="language"
                onSubmit={e => props.handleFormSubmit(e, props.difficultyLevel, props.taskType, inputValue, props.fromLang)}
                className="mt-5">

            <Col className="d-flex justify-content-center mb-5">
              <SelectSearch
                className="select-search"
                options={options}
                onChange={renderValue}
                renderOption={renderLanguage}
                search
                filterOptions={fuzzySearch}
                placeholder="Select a translation language"
                emptyMessage="Not found"
                autoFocus={true}
                ref={inputDiv}
                value={inputValue}
              />
            </Col>

            <Col className="text-center mb-5" style={hideButton}>
              <Button className="holo_dark_huge_button" variant="dark" type="submit">Start Exercise</Button>
            </Col>
          </form>
        </Row>
      </Row>
    </Card>
  </Col>;

}

export default LanguageSelector;