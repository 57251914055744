import BookItem from "./BookItem";
import './BookList.css';
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import React, {useState, useEffect} from "react";
import Frame from "../ui/Frame";
import Card from "../ui/Card";

function BookList(props) {
  const [containerClass, setContainerClass] = useState("cloze_booklist_list_hide");

  // Load books from server
  useEffect(() => {
    if (props.books.length !== 0) {
      setContainerClass("")
    } else {
      setContainerClass("cloze_booklist_list_hide")
    }
  }, [props.books.length]);

  // Get book title
  function handleBookItem(event, sefrList) {
    props.getBookTitle(event.target.title, sefrList);
  }

  return (
    <Frame>
      <div className={containerClass}>
        <div className="pb-5">
          <Card>
            <div>
              <Col fluid="md" className="cloze_menu_top_middle px-3">
                <Row>
                  <ul className="cloze_booklist_list">
                    {props.books.map((book) => (
                      <BookItem
                        key={book.id}
                        id={book.id}
                        image={book.image}
                        title={book.title}
                        author={book.author}
                        cefr={book.cefr}
                        handleBookItem={handleBookItem}
                        sefrList={props.sefrList}
                      />
                    ))}
                  </ul>
                </Row>
              </Col>
            </div>
          </Card>
        </div>
      </div>
    </Frame>
  );
}

export default BookList;