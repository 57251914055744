import React from "react";
import './HomePage.css'


function HomePage() {
  return (
    <div>
      <div className="home-layout">
        <h1>Holodeck</h1>
        <h5>Lingvist Labs experimentation platform</h5>
        <div className="pt-3">
          <img src="images/holodeck.jpg" className='home-image' alt="holodeck"/>
        </div>
      </div>

    </div>
  );
}

export default HomePage;