import React, {Fragment, useEffect, useReducer, useRef, useState, useMemo} from 'react';
import './DataPipeline.css';
import Frame from "../../components/ui/Frame";
import Card from "../../components/ui/Card";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import SelectSearch, {fuzzySearch} from "react-select-search";
import {Button} from "react-bootstrap";
import {BACKEND_URL} from "../../App";
import {makeId} from "../../utils/makeId";

import TableContainer from './TableContainer';
import 'bootstrap/dist/css/bootstrap.min.css';


const reducer = (state, action) => {
  switch (action.type) {
    case "Increment":
      return action.payload.map((x) => (<div key={makeId(5)}>{x}</div>))
    default:
      return state;
  }
}


function DataPipeline() {
  document.body.style.backgroundColor = "#EEF0F4";
  document.body.style.overflow = 'auto';

  const [languagePair, setLanguagePair] = useState('');
  const [minGuesses, setMinGuesses] = useState('');
  const [intervals, setIntervals] = useState('');
  const [options, setOptions] = useState([]);
  const [hideButton, setHideButton] = useState({visibility: 'hidden'});
  const [startedMsg, setStartedMsg] = useState('');
  const [languagePairsCount, setLanguagePairsCount] = useState();
  const [refreshIcon, setRefreshIcon] = useState(<i className="fa fa-refresh refresh-icon" aria-hidden="true"></i>);

  const [state, dispatch] = useReducer(reducer, []);
  const languagePairRef = useRef(null);
  const minGuessesRef = useRef(null);
  const intervalRef = useRef(null);

  useEffect(() => {
    let timer = setInterval(() => {
      fetch(BACKEND_URL + '/api/guess_api/get_jobs', {
        method: 'POST', headers: {
          'Content-Type': 'application/json'
        }, body: JSON.stringify({
          language_pair: ''
        })
      }).then(response => response.json())
        .then(data => {
          const url = window.location.href;
          if (url.includes('data-pipeline') === true) {
            dispatch({type: "Increment", payload: data.jobs})
          } else {
            clearInterval(timer)
          }
        });
    }, 1000);
  }, []);

  const [refreshData, setRefreshData] = useState(false);
  const [data, setData] = useState([]);
  useEffect(() => {
    setRefreshIcon(<span className="loading">Loading...</span>)
    fetch(BACKEND_URL + '/api/guess_api/get_overwiev', {
      method: 'POST', headers: {
        'Content-Type': 'application/json'
      }, body: JSON.stringify({
        overview: ''
      })
    }).then(response => response.json())
      .then(data => {
        setData(data)
        setRefreshIcon(<i className="fa fa-refresh refresh-icon" aria-hidden="true"></i>)
      });
  }, [refreshData]);

  useEffect(() => {
    fetch(BACKEND_URL + '/api/guess_api/get_language_pairs', {
      method: 'POST', headers: {
        'Content-Type': 'application/json'
      }, body: JSON.stringify({
        overview: ''
      })
    }).then(response => response.json())
      .then(data => {
        setOptions(data.language_pairs);
        setLanguagePairsCount("Choose a language pair (" + data.language_pairs_count + ")");
      });
  }, []);


  function renderLanguagePair(e) {
    if (minGuesses !== '' && intervals !== '') {
      setHideButton({visibility: 'visible'});
    }
    setLanguagePair(e);
  }

  function renderMinGuesses(e) {
    if (languagePair !== '' && intervals !== '') {
      setHideButton({visibility: 'visible'});
    }
    setMinGuesses(e.target.value);
  }

  function renderInterval(e) {
    if (languagePair !== '' && minGuesses !== '') {
      setHideButton({visibility: 'visible'});
    }
    setIntervals(e.target.value);
  }

  function renderLanguage(props, option, snapshot, className) {
    return (<button {...props} className={className} type="button">
      <span>{option.name}</span>
    </button>);
  }


// Get list of all language pairs in the database
  function getLanguagePairs(language_pair, min_guesses, interval, max_n_users, count_users_only) {
    fetch(BACKEND_URL + '/api/guess_api/run_data_pipeline', {
      method: 'POST', headers: {
        'Content-Type': 'application/json'
      }, body: JSON.stringify({
        language_pair: language_pair,
        min_guesses: min_guesses,
        interval: interval
      })
    }).then(response => response.json())
      .then(data => {
        setStartedMsg(data);
        setLanguagePair('');
        minGuessesRef.current.value = '';
        intervalRef.current.value = '';
        setMinGuesses('');
        setIntervals('');

        setTimeout(() => {
          setStartedMsg('');
        }, 4000);
        try {
          document.getElementById("next_button").blur();
        } catch (err) {
        }
      });
  }

  function handleFormSubmit(event) {
    event.preventDefault();
    setHideButton({visibility: 'hidden'});
    getLanguagePairs(languagePair, minGuesses, intervals + ' months', '1000000000000', 'False');
  }

  const columns = useMemo(
    () => [
      {
        Header: 'Modified',
        accessor: 'object_last_modified',
      },
      {
        Header: 'Language Pair',
        accessor: 'language_pair',
      },
      {
        Header: 'Min Guesses',
        accessor: 'min_guesses',
      },
      {
        Header: 'Interval in Months',
        accessor: 'interval_in_months',
      },
      // {
      //   Header: 'Users',
      //   accessor: 'users',
      // },
      {
        Header: 'Status',
        accessor: 'status',
      },
    ],
    []
  );

  const UpdateTableData = ()=> {
    setRefreshData(!refreshData);
    console.log("UpdateTableData");
  }


  return (<Fragment>
    <Frame>
      <h3 className="holodeck_h3 px-4 pb-1">Data Pipeline</h3>
      <Card>
        <Row className="cloze_menu_top_header py-4">
          <Col fluid="md" className="px-3 mb-4">
            <Row className="cloze_menu_top_header py-4">
              <Col>
                <div className={'holodeck_h4 px-4 pt-3'}>
                  <span>Select single run arguments</span>
                </div>

                <Form onSubmit={e => handleFormSubmit(e)} className="mt-3">

                  <Row className="cloze_menu_top_header py-4">
                    <Form.Group className="mb-3" controlId="interval">
                      <Form.Label>Language Pair</Form.Label>
                      <Col className="d-flex  mb-4 " style={{width: '280px'}}>
                        <SelectSearch
                          className="select-search"
                          options={options}
                          onChange={renderLanguagePair}
                          renderOption={renderLanguage}
                          search
                          filterOptions={fuzzySearch}
                          placeholder={languagePairsCount}
                          emptyMessage="Loading..."
                          autoFocus={true}
                          ref={languagePairRef}
                          value={languagePair}
                        />
                      </Col>
                    </Form.Group>

                    <div>
                      <Form.Group className="mb-4 data-pipeline-input" controlId="minGuesses">
                        <Form.Label>Min Guesses</Form.Label>
                        <Form.Control type="number" min="0" name="minGuesses"
                                      onChange={renderMinGuesses} placeholder="Enter the number of guesses"
                                      ref={minGuessesRef}/>
                      </Form.Group>
                    </div>

                    <div>
                      <Form.Group className="mb-3 data-pipeline-input" controlId="interval">
                        <Form.Label>Interval in Months</Form.Label>
                        <Form.Control type="number" min="1" onChange={renderInterval}
                                      placeholder="Enter the interval in months" ref={intervalRef}/>
                      </Form.Group>
                    </div>

                    <div>
                      <Col className="pt-4">
                        <Button style={hideButton} className="holo_dark_button" variant="dark" type="submit">Run
                          Pipeline</Button>
                        {startedMsg && <Alert className='data-pipeline-input' key='success' variant='success'>
                          {startedMsg}
                        </Alert>}
                      </Col>
                    </div>
                  </Row>
                </Form>
              </Col>
            </Row>
          </Col>
          <Col>
            <div className={'holodeck_h4 px-4 pt-5 data-pipeline-jobs'}>
              <div className={'pb-3'}>Current jobs queue</div>
            </div>
            {state}
          </Col>
        </Row>
      </Card>
      <div className={'pt-3'}></div>
      <Card>
        <Row className="cloze_menu_top_header pb-5 pt-4 px-3">
          <span className="refresh-right pb-3 px-4" onClick={UpdateTableData}>{refreshIcon}</span>
          <Col className="px-3">
            <TableContainer columns={columns} data={data}/>
          </Col>
        </Row>
      </Card>

    </Frame>
  </Fragment>);
}

export default DataPipeline;