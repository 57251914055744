import React, {Fragment, useCallback, useMemo, useState, useRef, useEffect} from 'react';
import './PersonalizedLearning.css';
import Frame from "../../components/ui/Frame";
import Card from "../../components/ui/Card";
import {BACKEND_URL} from "../../App";
import {useDropzone} from "react-dropzone";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import {useContext} from 'react';
import AuthContext from "../../store/auth-context";
import {Button} from "react-bootstrap";
import PersonalizedExerciseList from "../../components/personalization/PersonalizedExerciseList";
import Modal from "../../components/ui/Modal";
import PersonalizedSettingsModal from "../../components/ui/PersonalizedSettingsModal";
import PersonalizedExercise from "./PersonalizedExercise";


// Drag and drop file upload styling
const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '16px',
  borderWidth: 2,
  borderRadius: 8,
  borderColor: '#B2B8CB',
  borderStyle: 'dashed',
  backgroundColor: '#EEF0F4',
  color: '#2C3143',
  outline: 'none',
  transition: 'border .24s ease-in-out',
  // height: '240px',
  // marginTop: '10px'
};

const focusedStyle = {
  borderColor: '#6CD5E5'
};

const acceptStyle = {
  borderColor: '#43D281',
  backgroundColor: '#f4f5f8',
};

const rejectStyle = {
  borderColor: '#FE5C55'
};


function PersonalizedLearning(props) {
  document.body.style.backgroundColor = "#EEF0F4";
  document.body.style.overflow = 'auto';
  const authCtx = useContext(AuthContext);

  // On first render
  useEffect(() => {
    localStorage.setItem('exerciseHasStarted', 'false');
    getAllExercises();
    handleUserResponse('', 'Stop exercise');
    settingsModalHandler('e', {}, 'get_user_settings');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let currentUser = authCtx.user;
  let currentGoogleId = authCtx.googleId;
  if (!currentUser) {
    currentUser = 'no_user_data'
  }

  const user = {'name': currentUser, 'id': currentGoogleId};

  const [reject, setReject] = useState(false);
  const [fileTypeWarning, setFileTypeWarning] = useState('cloze_booklist_list_hide');
  const [fileCountWarning, setFileCountWarning] = useState('cloze_booklist_list_hide');
  const [fileSizeWarning, setFileSizeWarning] = useState('cloze_booklist_list_hide');
  const [languageWarning, setLanguageWarning] = useState('cloze_booklist_list_hide');
  const [textExistsWarning, setTextExistsWarning] = useState('cloze_booklist_list_hide');
  const [taskLoading, setTaskLoading] = useState(false);
  const [uploadTextClass, setUploadTextClass] = useState('personal_learning_select_header text-center');
  const [uploadedFileName, setUploadedFileName] = useState('');
  const [showUploadDiv, setShowUploadDiv] = useState(false);
  const [userExerciseList, setUserExerciseList] = useState([]);
  const [modalPlaceholder, setModalPlaceholder] = useState('Text name');
  const [displayExercise, setDisplayExercise] = useState(false);
  const [textName, setTextName] = useState('');
  const [textExample, setTextExample] = useState('');
  const [exerciseStage, setExerciseStage] = useState('');
  const [chunks, setChunks] = useState([]);
  const [cancelExercise, setCancelExercise] = useState(false);
  const [displayCancelButton, setDisplayCancelButton] = useState(true);
  const [enteredBookName, setEnteredBookName] = useState('');
  const [modal, setModal] = useState();
  const [settingsModal, setSettingsModal] = useState();
  const [userSettings, setUserSettings] = useState();
  const [exerciseError, setExerciseError] = useState(false);
  const [saveSettingsButtonState, setSaveSettingsButtonState] = useState(0);

  const createButton = useRef(null);

  // Add text name
  const enterTextData = (book_name) => {
    if (enteredBookName.trim().length === 0) {
      setModal({
        title: 'The text is stored',
        message: 'Please enter the name of the text'
      });
      return;
    }
    props.onAddTextData(enteredBookName);
    setEnteredBookName('');
  };

  //Get all exercises
  function getAllExercises() {
    fetch(BACKEND_URL + '/api/guess_api/get_personal_exercises', {
      method: 'POST', body: JSON.stringify({
        user: user
      }), headers: {
        'Content-type': 'application/json; charset=UTF-8'
      },
    })
      .then(function (response) {
          if (response.status !== 200) {
            console.log('Looks like there was a problem. Status Code: ' + response.status);
            return;
          }
          response.json().then(function (data) {
            setUserExerciseList(data);
          });
        }
      )
      .catch(function (err) {
        console.log('Fetch Error :-S', err);
      });
  }

  // Store text
  function storeTextinDB(formData) {
    setTaskLoading(true);
    setUploadTextClass('cloze_hide_input');
    fetch(BACKEND_URL + '/api/guess_api/personalization_store_text', {
      method: 'POST',
      body: formData,
    }).then(response => response.json())
      .then(data => {
        if (data.error) {
          //Language check
          if (data.error.includes('The text is not in English')) {
            setLanguageWarning('cloze_booklist_warning');
          }

          //Text exists check
          if (data.error.includes('Text exists')) {
            setTextExistsWarning('cloze_booklist_warning');
          }

          setTaskLoading(false);
          setUploadTextClass('personal_learning_select_header text-center');
          setReject(true);
          setTimeout(function () {
            setReject(false);
            setLanguageWarning('cloze_booklist_list_hide');
            setTextExistsWarning('cloze_booklist_list_hide');
          }, 5000);
        } else {
          enterTextData(data.book_name);
          setShowUploadDiv(false);
          setTaskLoading(false);
          setUploadTextClass('personal_learning_select_header text-center');
        }
      });
  }

  // Drag and drop settings
  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      const fileType = file.name.split('.').pop();
      const allowedFileTypes = ['txt', 'epub'];
      const validFileType = allowedFileTypes.includes(fileType);
      const validFileSize = file.size < 10000000;
      const validFileCount = acceptedFiles.length === 1;

      if (!validFileType || !validFileCount || !validFileSize) {
        setReject(true);
        !validFileType && setFileTypeWarning('cloze_booklist_warning');
        !validFileCount && setFileCountWarning('cloze_booklist_warning');
        !validFileSize && setFileSizeWarning('cloze_booklist_warning');
        setTimeout(function () {
          setReject(false);
          setFileTypeWarning('cloze_booklist_list_hide');
          setFileCountWarning('cloze_booklist_list_hide');
          setFileSizeWarning('cloze_booklist_list_hide');
          setLanguageWarning('cloze_booklist_list_hide');
        }, 5000);
        return
      }

      const reader = new FileReader();
      reader.onabort = () => console.log('file reading was aborted');
      reader.onerror = () => console.log('file reading has failed');
      reader.onload = () => {
        const formData = new FormData();

        // Include user data
        user.img = './images/books/random_covers/img_' + Math.floor(Math.random() * 12) + '.svg';
        const userJSON = JSON.stringify(user);
        const blob = new Blob([userJSON], {type: 'text/html'});

        formData.append("file", file);
        setUploadedFileName(file.name);
        formData.append("user", blob);
        storeTextinDB(formData);
      }
      reader.readAsArrayBuffer(file)
    })
    // eslint-disable-next-line
  }, [])

  const {
    getRootProps, getInputProps, isFocused, isDragAccept
  } = useDropzone({onDrop});

  const style = useMemo(() => ({
    ...baseStyle, ...(isFocused ? focusedStyle : {}), ...(isDragAccept ? acceptStyle : {}), ...(reject ? rejectStyle : {})
  }), [isFocused, isDragAccept, reject]);

  const uploadModalHandler = (event, textName) => {
    event.preventDefault();
    if (!textName) {
      setModal({
        title: 'The text is stored',
        message: 'Please enter the name of the text',
        error: 'Please enter valid text name'
      });

      // Generate random text name
      const textName = 'text_' + Math.floor(Math.random() * 1000000);
      setModalPlaceholder(textName);

      setTimeout(function () {
        setModal({
          title: 'The text is stored',
          message: 'Please enter the name of the text',
          error: ''
        });
      }, 3000);

      return;
    }

    // Save text name
    fetch(BACKEND_URL + '/api/guess_api/personalization_store_text', {
      method: 'POST', body: JSON.stringify({
        user: user,
        text_file_name: uploadedFileName,
        text_name: textName
      }), headers: {
        'Content-type': 'application/json; charset=UTF-8'
      },
    })
      .then(function (response) {
          if (response.status !== 200) {
            console.log('Looks like there was a problem. Status Code: ' + response.status);
            return;
          }
          response.json().then(function (data) {
            // Check if name already exists
            if (data === 'Text name already exists') {
              setModal({
                title: 'The text is stored',
                message: 'Please enter the name of the text',
                error: 'The name already exists, please enter another name'
              });

              // Generate random text name
              const textName = 'text_' + Math.floor(Math.random() * 1000000);
              setModalPlaceholder(textName);

              setTimeout(function () {
                setModal({
                  title: 'The text is stored',
                  message: 'Please enter the name of the text',
                  error: ''
                });
              }, 3000);

              return;
            }

            setModal(null);
            setShowUploadDiv(false);
            getAllExercises();
          });
        }
      )
      .catch(function (err) {
        console.log('Fetch Error :-S', err);
      });
  };

  const handleExerciseCreation = () => {
    setShowUploadDiv(true);
    createButton.current.blur();
  }

  // Run Web Worker for sending answers and getting results
  function runWorker(textName) {
    localStorage.setItem('answerNumber', '0');
    fetch(BACKEND_URL + '/api/guess_api/personal_run_exercise', {
      method: 'POST', body: JSON.stringify({
        data: {
          from: 'run_worker',
          user: user,
          text_name: textName
        }
      }), headers: {
        'Content-type': 'application/json; charset=UTF-8'
      },
    })
      .then(function (response) {
          if (response.status !== 200) {
            console.log('runworker error. Status Code:' + response.status);
          }
        }
      )
      .catch(function (err) {
        console.log('checkReadiness Error :-S', err);
      });
  }

  const checkReadiness = (textName, fetchCategory) => {
    fetch(BACKEND_URL + '/api/guess_api/get_personal_text', {
      method: 'POST', body: JSON.stringify({
        data: {
          from: 'user_response',
          user: user,
          text_name: textName,
          fetch_category: fetchCategory
        }
      }), headers: {
        'Content-type': 'application/json; charset=UTF-8'
      },
    })
      .then(function (response) {
          if (response.status !== 200) {
            console.log('checkReadiness error: ' + response.status);
            return;
          }
          response.json().then(function (data) {
            if (data) {
              if (data.resp_type === 'error') {
                setExerciseError(true);
                setDisplayCancelButton(true);
              }
              if (data.resp_type === 'ready') {
                setExerciseStage('ready');
                const dataChunks = JSON.parse(data.chunks);
                setChunks(dataChunks);
                setDisplayCancelButton(true);

                // Check if user has already started the exercise
                const timeOut = setTimeout(checkExerciseStatus, 50 * 60000);

                function checkExerciseStatus() {
                  if (localStorage.getItem('exerciseHasStarted') === 'true') {
                    clearTimeout(timeOut);
                    localStorage.setItem('exerciseHasStarted', 'false');
                    setCancelExercise(false);
                  } else {
                    handleUserResponse('', 'Stop exercise');
                    setCancelExercise(true);
                    alert('Your exercise has been terminated due to inactivity: exercise not started within 5 minutes.');
                  }
                }
              }
            }
          });
        }
      )
      .catch(function (err) {
        console.log('checkReadiness Error :-S', err);
      });
  }

  const handleUserResponse = (textName, fetchCategory) => {
    setCancelExercise(false);
    setDisplayCancelButton(false);

    if (fetchCategory !== 'Stop exercise') {
      setDisplayExercise(true);
      setTextName(textName);
    }

    // Send text name to server and get text back
    fetch(BACKEND_URL + '/api/guess_api/get_personal_text', {
      method: 'POST', body: JSON.stringify({
        data: {
          from: 'user_response',
          user: user,
          text_name: textName,
          fetch_category: fetchCategory
        }
      }), headers: {
        'Content-type': 'application/json; charset=UTF-8'
      },
    })
      .then(function (response) {
          if (response.status !== 200) {
            console.log('get_personal_text - looks like there was a problem. Status Code: ' + response.status);
            return;
          }
          response.json().then(function (data) {
            if (data.resp_type === 'text_example') {
              setTextExample(data.text);
              runWorker(textName);
              // Wait for 1 sec and check if text is ready
              setTimeout(function () {
                checkReadiness(data.text_name, 'Check readiness');
              }, 1000);
            } else if (data.resp_type === 'stop') {
              // console.log('All stopped');
            }
          });
        }
      )
      .catch(function (err) {
        console.log('Fetch Error :-S', err);
      });
  }

  const handlePrevious = () => {
    setDisplayExercise(false);
    setExerciseStage('');
    handleUserResponse(textName, 'Stop exercise');
    setChunks([]);
  }

  const openSettings = () => {
    setSettingsModal(true);
  }

  const closeSettings = () => {
    setSettingsModal(false);
    setSaveSettingsButtonState(0);
  }

  const settingsModalHandler = (event, values, request) => {
    try {
      event.preventDefault();
    } catch (e) {
    }
    fetch(BACKEND_URL + '/api/guess_api/user_settings', {
      method: 'POST', body: JSON.stringify({
        data: {
          request: request,
          user: user,
          values: values
        }
      }), headers: {
        'Content-type': 'application/json; charset=UTF-8'
      },
    })
      .then(function (response) {
          if (response.status !== 200) {
            console.log('update_tasks_settings - looks like there was a problem. Status Code: ' + response.status);
            return;
          }
          response.json().then(function (data) {
            setUserSettings(JSON.parse(data.user_settings));
            if (data.resp_type === 'settings_stored') {
              setSaveSettingsButtonState(saveSettingsButtonState + 1);
            }
          });
        }
      )
      .catch(function (err) {
        console.log('Fetch Error :-S', err);
      });
  }

  const changeExerciseErrorStatus = () => {
    setExerciseError(false);
  }

  return (
    <Fragment>
      <div className="personal-learning-container">
        {displayExercise ?
          <PersonalizedExercise textName={textName} handlePrevious={handlePrevious} setExample={textExample}
                                exerciseStage={exerciseStage} chunks={chunks} cancelExercise={cancelExercise}
                                displayCancelButton={displayCancelButton} handleUserResponse={handleUserResponse}
                                exerciseError={exerciseError} changeExerciseErrorStatus={changeExerciseErrorStatus}/> :
          <div>
            {modal && (
              <Modal
                title={modal.title}
                message={modal.message}
                error={modal.error}
                onSubmit={uploadModalHandler}
                placeholder={modalPlaceholder}
              />
            )}

            {settingsModal && (
              <PersonalizedSettingsModal
                userSettings={userSettings}
                onSubmit={settingsModalHandler}
                closeSettings={closeSettings}
                saveSettingsButtonState={saveSettingsButtonState}
              />
            )}

            <Frame>
              <Card>
                <Col className="px-3 pb-1 mb-4">
                  <Row className="personal-learning-top-header">

                    <Row className={"personal-learning-right-padding"}>
                      <Col md={12} className={"personal-learning-header"}>
                        <h3 className="holodeck_h3">Personalized Learning</h3>
                        <div className={"personal-learning-icon-right"} onClick={openSettings}>
                          <img src="icons/settings.svg" alt="Settings"/>
                        </div>
                      </Col>
                    </Row>

                    <Col md={8}>
                    <span>Learn the language and create a learning plan based on the personalized text.<br/>
            Learn the vocabulary you need and solve related learning tasks.<br/><br/>
            Please upload a text or book with some special content and we will create an<br/>
            individual learning plan for you.</span>
                    </Col>
                    <Col md={4} className="personal-learning-text-right">
                      <Button className="holo_dark_button" variant="dark" onClick={handleExerciseCreation}
                              ref={createButton}>Create New Exercise</Button>
                    </Col>
                  </Row>
                </Col>
              </Card>

              {/*Upload*/}
              {showUploadDiv && (
                <Card>
                  <Col fluid="md" className="px-3 pb-1 mb-5">
                    <div>
                      <Row className={uploadTextClass}>
                        <h4 className="holodeck_h4">&nbsp;&nbsp;Upload your own text</h4>
                      </Row>
                      <Row className="mx-3 mt-1 mb-4">
                        {taskLoading ? <Row>
                          <div className="text-center mt-3">
                            <h4 className="holodeck_h4">Please wait, loading the text and creating the task.</h4>
                          </div>
                          <div className="text-center py-5">
                            <img src="icons/task_preloader.svg" alt="Task preloader"/>
                          </div>
                        </Row> : <div className="mb-4">
                          <div  {...getRootProps({
                            style,
                          })}>
                            <input {...getInputProps()} />
                            <p>Drag and drop book file here, or click to select files.</p>
                            <p className={"cloze_booklist_text_file"}><img src="icons/text_file.svg"
                                                                           alt="Text file"/> (TXT,
                              EPUB)
                            </p>
                            <p className={fileTypeWarning}>Please select the appropriate file type.</p>
                            <p className={fileCountWarning}>Please select only one file.</p>
                            <p className={fileSizeWarning}>Please select a file less than 10 MB.</p>
                            <p className={languageWarning}>The text is not in English, please select an English
                              test.</p>
                            <p className={textExistsWarning}>The text has already been downloaded.</p>
                          </div>
                        </div>}
                      </Row>
                    </div>
                  </Col>
                </Card>)}

              {/*Display when exercise list lenght is creater than 0*/}
              {userExerciseList.length > 0 && (
                <div>
                  <PersonalizedExerciseList handleUserResponse={handleUserResponse}
                                            exercises={userExerciseList}/>
                </div>
              )}
            </Frame>
          </div>
        }
      </div>
    </Fragment>
  );
}

export default PersonalizedLearning;