import classes from './PersonilizedExerciseItem.module.css'

function PersonilizedExerciseItem(props) {

  const title = props.text_name

  const handleEvent = (event) => {
      props.handleUserResponse(title, 'Get text example');
    }

  return (
    <li className={classes.personalized_flex_item}>
      <div title={title} className={classes.personalized_top}
            onClick={handleEvent}>

        <div title={title} className={classes.personalized_image}>
          <img width={'120px'} title={title} src={props.image} alt={props.title}/>
        </div>

        <div title={title} className={classes.personalized_title}>
          {props.text_name}
        </div>

        <div title={title} className={classes.personalized_author}>
          {props.author}
        </div>

      </div>
    </li>
  );
}

export default PersonilizedExerciseItem;