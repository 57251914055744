import React, {useEffect, useState, useRef} from 'react';
import '../../css/animate.css';
import './ParallelReading.css'

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "../../components/ui/Card";
import Frame from "../../components/ui/Frame";
import LineTwo from "../../components/ui/LineTwo";
import {languageList} from "../../utils/languages";


function ParallelReading(props) {

  useEffect(() => {
    document.body.style.backgroundColor = "#eef0f4";
    document.body.style.overflowX = "hidden";
  }, []);

  const origText = useRef(null);
  const translText = useRef(null);
  const previousButton = useRef(null);
  const nextButton = useRef(null);

  const textData = props.textData;

  const [arrowColor, setArrowColor] = useState('icons/big_back_arrow.svg');
  const [destLang, setDestLang] = useState(textData.lang_dest);

  const onMouseHover = () => {
    setArrowColor("icons/big_back_arrow_hover.svg")
  }

  const onMouseLeave = () => {
    setArrowColor("icons/big_back_arrow.svg")
  }

  // Insert new lines if needed
  let finalText = [];
  let text_element;
  text_element = textData.cloze_text_no_gaps.split('\n');

  text_element.forEach((part, i) => {
    finalText.push(part);
    finalText.push(<span key={'new_line' + i} className="cloze_br"/>);
  })
  finalText.pop();

  // Insert new lines into translated text if needed
  let finalTranslatedText = [];
  try {
    let translated_text_element;
    translated_text_element = textData.translated_text.split('\n');

    translated_text_element.forEach((part, i) => {
      finalTranslatedText.push(part);
      finalTranslatedText.push(<span key={'new_line' + i} className="cloze_br"/>);
    })
    finalTranslatedText.pop();
  } catch (err) {
    finalTranslatedText = 'Translation error';
  }


  useEffect(() => {
    translText.current.style.height = 'auto';
    origText.current.style.height = 'auto';

    const origHeight = origText.current.offsetHeight;
    const translHeight = translText.current.offsetHeight;

    if (origHeight > translHeight) {
      translText.current.style.height = origHeight + 'px';
    } else {
      origText.current.style.height = translHeight + 'px';
    }

    if (textData.text_part_number === 0) {
      previousButton.current.style.visibility = 'hidden';
    } else {
      previousButton.current.style.visibility = 'visible';
    }

    if (textData.text_part_number + 1 === textData.no_of_text_parts) {
      nextButton.current.style.visibility = 'hidden';
    } else {
      nextButton.current.style.visibility = 'visible';
    }

    setDestLang(textData.lang_dest)
  }, [textData]);


  const handlePrevious = () => {
    const task_data = {'chapter_number': textData.chapter_number, 'text_part_number': textData.text_part_number - 1};
    props.getTask(textData.book_name, task_data, false, 'parallel', textData.text_difficulty, textData.task_type, destLang);
  }

  const handleNext = () => {
    const task_data = {'chapter_number': textData.chapter_number, 'text_part_number': textData.text_part_number + 1};
    props.getTask(textData.book_name, task_data, false, 'parallel', textData.text_difficulty, textData.task_type, destLang);
  }


  return (<Frame>
    <Row className="pb-2">
      <Col sm={12} className="px-4 pb-2">
        <div className="col-12">
            <span className="cloze_select_back_h3" onClick={() => props.dataFromExercize('menu')}
                  onMouseOver={onMouseHover}
                  onMouseLeave={onMouseLeave}>
              <img className="cloze_select_link" src={arrowColor} alt="menu"/>
             Book selection</span>
        </div>
      </Col>
    </Row>

    <Row className="mb-3">
      <Col sm={12}>
        <Card>
          <Row className="p-3">
            <Col className="mx-2 pt-1 text-start">
              <h3 className="holodeck_h3">{textData.book_name}</h3>
              <h6>{textData.author}</h6>
              <h6>Chapter {textData.chapter_number},
                Part {textData.text_part_number + 1}/{textData.no_of_text_parts}</h6>
            </Col>
            <Col className="text-end pt-1 px-4">
              <span className="cloze_parallel_sefr">{textData.pred_cefr}</span>
            </Col>
          </Row>
        </Card>
      </Col>
    </Row>

    <Row className="mb-3">
      <Col sm={6}>
        <Card>
          <Row ref={origText}>
            <Row className="p-3">
              <Col xl={2} className="text-start pt-1">
                {(textData.from_lang === 'de') ? <img className="cloze_select_dest_flag"
                                                      src="images/german.svg" alt="German"/> :
                  <img className="cloze_select_dest_flag" src="images/english.svg" alt="English"/>}
              </Col>
              <Col className="mx-2 pt-1 text-start" style={{marginBottom: '16px'}}>
                <h3 className="holodeck_h3">{textData.book_name}</h3>
                <h6>{textData.author}</h6>
              </Col>
              <LineTwo/>
            </Row>

            <Row>
              <Col className="cloze_parallel_text">
                {finalText}
              </Col>
            </Row>
          </Row>
        </Card>
      </Col>

      <Col sm={6}>
        <Card>
          <Row ref={translText}>
            <Row className="p-3">
              <Col className="mx-2 pt-1 text-start" style={{marginBottom: '8px'}}>
                <h3 className="holodeck_h3">{textData.translated_book_name}</h3>
                <h6>{textData.translated_author}</h6>
              </Col>
              <Col xl={6} className="text-end pt-1">
                <h4 className="holodeck_h4">{languageList(textData.lang_dest)}</h4>
              </Col>
              <LineTwo/>
            </Row>
            <Row>
              <Col className="cloze_parallel_text">
                {finalTranslatedText}
              </Col>
            </Row>
          </Row>
        </Card>
      </Col>
    </Row>

    <Row className="mb-3">
      <Col sm={12}>
        <Card>
          <Row className="p-3">
            <Col className="text-end pt-1 px-4">
              <img className="cloze_parallel_round"
                   src="icons/left_round.svg"
                   alt="Previous" onClick={handlePrevious} ref={previousButton}/>
              <img className="cloze_parallel_round"
                   src="icons/right_round.svg"
                   alt="Next" onClick={handleNext} ref={nextButton}/>
            </Col>
          </Row>
        </Card>
      </Col>
    </Row>

  </Frame>);
}

export default ParallelReading;
