import React, {Fragment, useEffect, useRef, useState} from "react";
import './Tasks.css';
import Card from "../../../components/ui/Card";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import {Button} from "react-bootstrap";


function Summary(props) {

  const continueButton = useRef(null);
  const [continueButtonFocus, setContinueButtonFocus] = useState(true);
  const [text, setText] = useState('');

  useEffect(() => {
    continueButton.current.disabled = false;
    localStorage.setItem('setEnterKeyPressed', '0');

    let summary = '';
    props.exerciseData.summary.forEach((item, index) => {
      summary += item[0] + ' ';
    });
    setText(summary);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleContinue() {
    continueButton.current.disabled = true;
    props.getQuestion(props.textName, 'Post answer', '');
    setContinueButtonFocus(false);
  }

  useEffect(() => {
    const listener = event => {
      if ((event.keyCode === 9 || event.code === "Enter" || event.code === "NumpadEnter") && localStorage.getItem('setEnterKeyPressed') === '0') {
        event.preventDefault();
        if (continueButtonFocus) {
          continueButton.current.focus();
          handleContinue();
        }
      }
    }
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [continueButtonFocus]);

  return (
    <Fragment>
      <div className={'mb-3'}>
        <Card>
          <Row>
            <div>
              <div className={'pe-flex-space-between'}>
                <h3 className="holodeck_h3 px-4 pt-4">Text summary</h3>
                <div className="px-4 pt-4">
                  <img src="icons/personalization.svg" alt="Text"/>
                </div>
              </div>
              <div className={'px-4 pb-4'}>
                Read this summary of your text and press continue when you are ready.
              </div>
            </div>
          </Row>
        </Card>
      </div>
      <div className={'mb-3'}>
        <Card>
          <div className={'tasks_boarder'}>
            <h4 className="holodeck_h4 mb-3 pt-4 px-4">{props.textName}</h4>
          </div>
          <div className="task-text px-4 pb-5 pt-4">
            {text}
          </div>


          <Col sm={12}>
            <div className="tasks_bottom pe-flex-end p-4">
              <Button className="pe-start-button cloze_select_outline_dark" variant="dark"
                      onClick={handleContinue} ref={continueButton}>
                {props.loading ? <i className="fa fa-spinner fa-spin"/> : null}
                {props.loading ? 'Loading' : 'Continue'}
              </Button>
            </div>
          </Col>
        </Card>
      </div>
    </Fragment>
  )
    ;
}

export default Summary;