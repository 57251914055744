import React, {useEffect, useState, useRef} from 'react';
import Row from "react-bootstrap/Row";

import './ClozeSelect.css'
import Col from "react-bootstrap/Col";
import Stack from "react-bootstrap/Stack";
import {Button} from "react-bootstrap";
import Frame from "../../components/ui/Frame";
import Card from "../../components/ui/Card";
import LineC from "../../components/ui/LineC";
import TaskSelector from "../../components/clozeBooks/TaskSelector";
import DifficultySelector from "../../components/clozeBooks/DifficultySelector";
import LanguageSelector from "../../components/clozeBooks/LanguageSelector";

function ClozeSelect(props) {
  const leftDiv = useRef(null);

  const [leftDivHeight, setLeftDivHeight] = useState({});
  const [selectStep, setSelectStep] = useState('task');
  const [difficultyLevel, setDifficultyLevel] = useState('easy');
  const [taskType, setTaskType] = useState('verb');
  const [difficChecked, setDifficChecked] = useState('checked');
  const [taskChecked, setTaskChecked] = useState('checked');
  const [taskName, setTaskName] = useState('Fill in the blank: Verb');
  const [hideClass, setHideClass] = useState('');
  const [leftHeight, setLeftHeight] = useState({});

  const taskData = {
    task_type: "verb", text_difficulty: "", chapter_number: 1, text_part_number: 0,
  }

  function handleFormSubmit(event, difficultyLevel, taskType, dest_lang) {
    event.preventDefault();
    //  From language selector if task is uploadedTaskAny
    if (event.target.id === 'language' && taskType === 'uploadedTaskAny') {
      props.getTask('', props.receivedTask, false, '', '', 'uploadedTaskAny', dest_lang);
    } else {

      // From verb or any task to language selector
      if (event.target.id === 'task' && (taskType === 'verb' || taskType === 'any')) {
        props.sSelector('');
        setTaskType(taskType);
        setSelectStep('languageSelector');
      }

      // From parallel task to language selector
      if (event.target.id === 'task' && taskType === 'parallel') {
        props.sSelector('');
        setSelectStep('languageSelector');
      }

      // // From verb or any task to language selector
      // if (event.target.id === 'difficulty') {
      //   props.sSelector('');
      //   setDifficultyLevel(difficultyLevel);
      //   setTaskType(taskType);
      //   setSelectStep('languageSelector');
      // }

      // From language selector if task is parallel
      if (event.target.id === 'language' && taskType === 'parallel') {
        props.getTask(props.selectedTitle, {
          chapter_number: 1,
          text_part_number: 0
        }, false, 'parallel', 'hard', 'verb', dest_lang);
      }

      // From language selector if task is any
      if (event.target.id === 'language' && taskType === 'any') {

      }

      // From language selector if task is verb or any
      if (event.target.id === 'language' && taskType !== 'parallel') {
        props.getTask(props.selectedTitle, taskData, false, 'exercise', difficultyLevel, taskType, dest_lang);
      }
    }
  }

  function handleDifficOptionChange(changeEvent) {
    setDifficultyLevel(changeEvent.target.id);
    (changeEvent.target.id === 'easy' ? setDifficChecked('checked') : setDifficChecked(''));
  }

  function handleTaskOptionChange(changeEvent, task_type) {
    setTaskName(task_type);
    setTaskType(changeEvent.target.id);
    (changeEvent.target.id === 'verb' ? setTaskChecked('checked') : setTaskChecked(''));
  }

  useEffect(() => {
    if (props.selector === 'task') {
      //
      // // Get full text with web worker
      // const worker = new Worker("/getFullText.worker.js", {type: "module"});
      // // Send book_name to worker
      // const postMessageData = {'book_name': props.selectedTitle, 'language': props.language};
      // worker.postMessage(postMessageData);
      //
      // worker.onmessage = function (e) {
      //   // Store text data for the user session
      //   if (typeof (Storage) !== "undefined") {
      //     sessionStorage.splitted_text = JSON.stringify(e.data.splitted_text);
      //     sessionStorage.tasks = JSON.stringify({0: {'cloze_text': '', 'correct': []}});
      //     console.log('Text stored in sessionStorage');
      //
      //     // // Get needed text part from session storage
      //     // const taskNo = 0;
      //     // const nthTask = (arr, n = 0) => (n > 0 ? arr.slice(n, n + 1) : arr.slice(n))[0];
      //     // const taskText = nthTask(JSON.parse(sessionStorage.splitted_text), taskNo);
      //     // console.log(taskText);
      //
      //   } else {
      //     alert('Sorry! No Web Storage support..')
      //   }
      // }

      setSelectStep('task')
    } else if (props.selector === 'difficulty') {
      setSelectStep('languageSelector')
    } else if (props.selector === 'parallel') {
      setSelectStep('parallel')
    }

    setLeftDivHeight(leftDiv.current.offsetHeight);
  }, [props.language, props.selectedTitle, props.selector]);

  function leftDivH(h) {
    // leftDiv.current.style.height = (leftDiv.current.style.height + h) + 'px'
  }

  useEffect(() => {
    if (props.fromUploadTask) {
      setSelectStep('languageSelector');
      setHideClass('cloze_hide_input');
      setLeftHeight({height: '234px'});
      setTaskType('uploadedTaskAny');
    } else {
      setSelectStep('task');
    }
  }, [props.fromUploadTask]);


  return <Frame>
    <Row className="mb-3">
      <Col sm={12}>
        <Card>
          <Row className="p-3">
            <Stack direction="horizontal" gap={3}>
              <div className="me-auto">
                <h3 className="holodeck_h3">Reading and fill in the blank exercises</h3>
                Select exercise settings
              </div>
              <span className="cloze_select_link"
                    onClick={e => props.handlePrevious(selectStep, props.sefrList, taskType, props.fromUploadTask)}>
              Previous</span>
              <div className="vr"/>
              <Button className="cloze_select_outline_dark" variant="outline-dark"
                      onClick={e => props.handleCancel(selectStep, props.fromUploadTask)}>Cancel</Button>
            </Stack>
          </Row>
        </Card>
      </Col>
    </Row>

    <Row className="mb-4 cloze_select_exercise">
      <Col sm={4} className="mb-3" ref={leftDiv}>
        <Card>
          <Row className="p-3 mb-2">
            <div className="me-auto mb-2">
              <h4 className="holodeck_h4 mb-3">My exercise</h4>
              <LineC/>
            </div>

            <div className={`me-auto pt-4 mb-2 ${hideClass}`}>
              <h4 className="holodeck_h4">CEFR level</h4>
            </div>
            <div className={`cloze_select_button_active ${hideClass}`}>
              <img className="cloze_select_check"
                   src="icons/checkmark_vector.svg"
                   alt="Checked"/>{props.selectedCefr}
            </div>


            <div className="me-auto mt-4 mb-2 py-2">
              <h4 className="holodeck_h4">Book selected</h4>
            </div>

            <Col className="container-fluid">
              <Row>
                <Col className="cloze_select_fixed_one pb-3">
                  {props.fromUploadTask ?
                    <img src='images/book_cover.jpg' alt="book" width="130px"/>
                    :
                    <img src={props.selectedImage} alt="book" width="130px"/>
                  }
                </Col>
                <Col>
                  <h4 className="holodeck_h4 cloze_select_title">
                    {props.fromUploadTask ? props.textFileName : props.selectedTitle}</h4>
                  {props.fromUploadTask ? '' : props.selectedAuthor}
                </Col>
              </Row>
            </Col>
          </Row>

          <Row className="cloze_select_exercise px-3" style={leftHeight}>
            <div>
              <h4 className="holodeck_h4">Exercise type</h4>
              {props.fromUploadTask ? 'Fill in the blank: any word' : taskName}
            </div>
          </Row>
        </Card>
      </Col>

      {(selectStep === 'task') && <TaskSelector handleFormSubmit={handleFormSubmit}
                                                handleTaskOptionChange={handleTaskOptionChange}
                                                checked={taskChecked} taskType={taskType}
                                                difficultyLevel={difficultyLevel} leftDivHeight={leftDivHeight}
                                                leftDivH={leftDivH}/>}

      {(selectStep === 'difficulty') && <DifficultySelector handleFormSubmit={handleFormSubmit}
                                                            handleDifficOptionChange={handleDifficOptionChange}
                                                            checked={difficChecked} difficultyLevel={difficultyLevel}
                                                            taskType={taskType} leftDivHeight={leftDivHeight}
                                                            leftDivH={leftDivH}/>}

      {(selectStep === 'languageSelector') &&
        <LanguageSelector handleFormSubmit={handleFormSubmit} fromLang={props.language} leftDivHeight={leftDivHeight}
                          leftDivH={leftDivH} taskType={taskType} difficultyLevel={difficultyLevel}/>}
    </Row>
  </Frame>;
}

export default ClozeSelect;