import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import {BrowserRouter} from "react-router-dom";
import {createRoot} from 'react-dom/client';
import './index.css';
import App from './App';
import {AuthContextProvider} from './store/auth-context';
import './css/hev7dim.css'
import {GoogleOAuthProvider} from '@react-oauth/google';

const container = document.getElementById('root');
const root = createRoot(container);

const clientId = process.env.REACT_APP_CLIENT_ID;

root.render(
  <GoogleOAuthProvider clientId={clientId}>
    <AuthContextProvider>
      <BrowserRouter>
        <App/>
      </BrowserRouter>
    </AuthContextProvider>
  </GoogleOAuthProvider>
);