import React, {useContext, useEffect, useRef, useState} from "react";
import Row from "react-bootstrap/Row";
import './PersonalizedExercise.css';
import './Fireworks.scss';

import Col from "react-bootstrap/Col";
import Card from "../../components/ui/Card";
import Stack from "react-bootstrap/Stack";
import {Button} from "react-bootstrap";
import Frame from "../../components/ui/Frame";
import LineTwo from "../../components/ui/LineTwo";
import ProgressBar from 'react-bootstrap/ProgressBar';
import {BACKEND_URL} from "../../App";
import AuthContext from "../../store/auth-context";
import Keywords from "./tasks/Keywords";
import Associate from "./tasks/Associate";
import ClozeVerb from "./tasks/ClozeVerb";
import NameFromDefinition from "./tasks/NameFromDefinition";
import Translate from "./tasks/Translate";
import {makeId} from "../../utils/makeId";
import ClozeShuffle from "./tasks/ClozeShuffle";
import Gg from "./tasks/Gg";
import Summary from "./tasks/Summary";
import ExerciseProgress from "./ExerciseProgress";


function PersonalizedExercise(props) {
  const authCtx = useContext(AuthContext);
  let currentUser = authCtx.user;
  let currentGoogleId = authCtx.googleId;
  if (!currentUser) {
    currentUser = 'no_user_data'
  }

  const user = {'name': currentUser, 'id': currentGoogleId};

  const startButton = useRef(null);
  const [progressBar, setProgressBar] = useState(0);
  const [exerciseStarted, setExerciseStarted] = useState(false);
  const [exerciseType, setExerciseType] = useState([]);
  const [exerciseHints, setExerciseHints] = useState([]);
  const [exerciseFinished, setExerciseFinished] = useState(false);
  const [exerciseError, setExerciseError] = useState(false);
  const [exerciseNumber, setExerciseNumber] = useState(-1);
  const [loading, setLoading] = useState(false);
  const [inputText, setInputText] = useState('Type your answer here');
  const [exerciseData, setExerciseData] = useState({});
  const [type, setType] = useState('');
  const [chunks, setChunks] = useState([]);


  // Fill progress bar during n seconds, then hide it
  useEffect(() => {
    const timeoutValue = 250;
    const timer = setInterval(() => {
      if (progressBar < 100) {
        // Set seconds for progress bar
        const seconds = 22;
        setProgressBar(progressBar + (timeoutValue / 10 / seconds)); //
      }
    }, timeoutValue);
    return () => clearInterval(timer);
  }, [progressBar]);

  const handleCancel = () => {
    document.body.style.overflow = 'auto';
    localStorage.setItem('answerNumber', '0');
    localStorage.setItem('exerciseHasStarted', 'false');
    props.handlePrevious()
    setExerciseStarted(false);
    setExerciseError(false);
    props.changeExerciseErrorStatus();
  }

  useEffect(() => {
    if (props.cancelExercise) {
      handleCancel();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.cancelExercise]);

  const getQuestion = (textName, fetchCategory, answer, newAnswerNumber, handleNext) => {
    const attributes = [textName, fetchCategory, answer, newAnswerNumber, handleNext];
    handleNext && setExerciseHints([]);
    setLoading(true);
    fetch(BACKEND_URL + '/api/guess_api/get_personal_text', {
      method: 'POST', body: JSON.stringify({
        data: {
          from: 'user_response', user: user, text_name: textName, fetch_category: fetchCategory, answer: answer
        }
      }), headers: {
        'Content-type': 'application/json; charset=UTF-8'
      },
    })
      .then(function (response) {
        if (response.status !== 200) {
          console.log('getQuestion - looks like there was a problem. Status Code: ' + response.status);
          return;
        }
        response.json().then(function (data) {
          // console.log('### data', data);

          const exerciseTypeCurrent = data.type + ' ' + data.task_type;
          setType(exerciseTypeCurrent);

          // Check if Translation task is finished and new task should be loaded
          if (type === 'name_from_definition undefined' && exerciseTypeCurrent !== type) {
            localStorage.setItem('newTask', 'true');
          }

          localStorage.setItem('responseReceived', 'true');
          data['attributes'] = attributes;
          setExerciseData(data);

          // Handle shuffled hints where needed
          if (data.resp_type === 'question') {
            !handleNext && setExerciseNumber(exerciseNumber + 1);
            setLoading(false);
            setExerciseStarted(true);

            if (data.type === 'associate') {
              setExerciseType(['AssociateTaskGenerator', 'Word association']);
            } else if (data.type === 'cloze' && data.task_type === 'SHUFFLE') {
              setExerciseType(['ClozeTaskGenerator of type SHUFFLE', 'Fill the gap from the shuffled words']);
            } else if (data.type === 'cloze' && data.task_type === 'VERB') {
              setExerciseType(['ClozeTaskGenerator of type VERB', 'Fill the gap with the correct verb']);
            } else if (data.type === 'translate') {
              setExerciseType(['TranslateTaskGenerator', 'Translate the missing part']);
            } else if (data.type === 'name_from_definition') {
              setExerciseType(['NameFromDefinitionTaskGenerator', 'Find the word from the definition']);
            }

            // Check if user has already inserted an answer
            const timeOut = setTimeout(checkExerciseStatus, 50 * 60000);

            function checkExerciseStatus() {
              const currentStoredAnswerNo = JSON.parse(localStorage.getItem('answerNumber'));
              if (newAnswerNumber !== currentStoredAnswerNo || !exerciseStarted) {
                clearTimeout(timeOut);
              } else {
                props.handleUserResponse('', 'Stop exercise');
                handleCancel();
                alert('Your exercise has been terminated due to inactivity: no answer given within 5 minutes.');
              }
            }
          } else if (data.resp_type === 'finished') {
            document.body.style.overflow = 'hidden';
            setExerciseFinished(true);
            setExerciseStarted(false);
          } else if (data.resp_type === 'error') {
            document.body.style.overflow = 'hidden';
            setExerciseError(true);
            setExerciseStarted(false);
          }
        });
      })
      .catch(function (err) {
        console.log('getQuestion Error :-S', err);
      });
  }

  useEffect(() => {
    if (exerciseData.error_message === "RateLimitError") {
      getQuestion(exerciseData.attributes[0], 'Get question');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [exerciseData]);

  const handleStartExercise = () => {
    localStorage.setItem('answerNumber', '0');
    // Store in local storage that user exercise has started
    localStorage.setItem('exerciseHasStarted', 'true');

    getQuestion(props.textName, 'Get question', '', 0);
    setExerciseFinished(false);
    startButton.current.blur();
  }

  // Change inpuText after 1,5 seconds
  useEffect(() => {
    const timer = setTimeout(() => {
      setInputText('');
    }, 1500);
    return () => clearTimeout(timer);
  }, [inputText]);

  // Set exerciseStarted to false
  useEffect(() => {
    if (props.cancelExercise === true) {
      handleCancel()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.cancelExercise]);

  // Set html for hints display
  useEffect(() => {
    exerciseHints.forEach((hint, i) => {
      const span = <span id={'hint_' + i}
                         className={exerciseType[0] === 'AssociateTaskGenerator' ? 'tasks_hint_assotiation' : 'tasks_hint_normal'}
                         key={makeId(5)}>
        <img id={"side_hint_img_" + i} className="cloze_ex_hide"
             src="icons/checkmark_vector.svg" alt="Checked"/>
        {hint}</span>;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [exerciseHints]);

  useEffect(() => {
    const correctedChunks = props.chunks.map((row) => {
      let chunk = ''
      if (row[1] === 'TranslateTaskGenerator') {
        chunk = 'Translate the missing part'
      } else if (row[1] === 'ClozeTaskGenerator of type VERB') {
        chunk = 'Fill the gap with the correct verb'
      } else if (row[1] === 'ClozeTaskGenerator of type SHUFFLE') {
        chunk = 'Fill the gap from the shuffled words'
      } else if (row[1] === 'AssociateTaskGenerator') {
        chunk = 'Word association'
      } else if (row[1] === 'NameFromDefinitionTaskGenerator') {
        chunk = 'Find the word from the definition'
      } else {
        chunk = row[1]
      }
      return [row[0], chunk]
    })

    setChunks(correctedChunks);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.chunks]);

  return (<Frame>
    <Row className="mb-3">
      <Col sm={12}>
        <Card>
          <Row className="p-3">
            <Stack direction="horizontal" gap={3}>
              <div className="me-auto">
                <h3 className="holodeck_h3">Personalized Learning</h3>
              </div>
              {props.displayCancelButton && <Button className="cloze_select_outline_dark" variant="outline-dark"
                                                    onClick={handleCancel}>{(exerciseFinished || exerciseError || props.exerciseError) ? 'List of exercises' : 'Cancel'}</Button>}
            </Stack>
          </Row>
        </Card>
      </Col>
    </Row>

    <Row>
      {!exerciseStarted ? <Col sm={8}>
        <Card>
          {(exerciseFinished || exerciseError || props.exerciseError) ? <Row>
              <Col sm={12} className={'pe-flex-float pt-4 pb-3 px-5'}>
                {exerciseFinished ? <img className={'pe-img'} src="icons/checkmark_vector.svg" alt="Checked"/> : null}
                <h4
                  className="holodeck_h4 mb-3">{exerciseFinished ? "The exercise has been completed successfully" :
                  "An error occurred during the task, please contact us!"}</h4>
              </Col>
            </Row> :

            <div className="me-auto mb-2 p-3">
              {(props.exerciseStage !== 'ready') ? <div>
                  {/*Create two columns in the row*/}
                  <Row>
                    <Col sm={10}>
                      <h4 className="holodeck_h4 mb-3">Please wait while we create a personalised learning plan for
                        you.</h4>
                    </Col>
                    <Col sm={2}>
                      <div className="px-3 pb-4 pe_flex_end">
                        {Math.floor(progressBar)}%
                      </div>
                    </Col>
                  </Row>
                  <ProgressBar className='pe-progress-bar' now={progressBar} variant={'success'}/>
                </div> :

                <div>
                  <Row>
                    <Col sm={6} className={'pe-flex-float pt-2'}>
                      <img className={'pe-img'} src="icons/checkmark_vector.svg" alt="Checked"/><h4
                      className="holodeck_h4 mb-3">Your learning plan has been created!</h4>
                    </Col>
                    <Col sm={6}>
                      <div className="px-3 pb-4 pe-flex-end">
                        <Button className="pe-start-button cloze_select_outline_dark" variant="dark"
                                onClick={handleStartExercise} ref={startButton}>
                          {loading ? <i className="fa fa-spinner fa-spin"/> : null}
                          {loading ? 'Loading' : 'Start the exercise'}
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </div>}

              <LineTwo/>

              {!exerciseStarted &&
                <div className="pe-text pe-text-gradient px-3 pb-5 pt-4">
                  {props.setExample}
                </div>}
            </div>}
        </Card>
      </Col> : <Col>
        <div>
          {exerciseStarted && exerciseData.type === 'summary' &&
            <Summary exerciseData={exerciseData}
                     textName={props.textName}
                     getQuestion={getQuestion}
                     loading={loading}/>}

          {exerciseStarted && exerciseData.type === 'keywords' &&
            <Keywords exerciseData={exerciseData}
                      textName={props.textName}
                      getQuestion={getQuestion}
                      loading={loading}/>}

          {exerciseStarted && exerciseData.task_type === 'VERB' &&
            <ClozeVerb exerciseData={exerciseData}
                       textName={props.textName}
                       getQuestion={getQuestion}
                       loading={loading}/>}

          {exerciseStarted && exerciseData.task_type === 'SHUFFLE' &&
            <ClozeShuffle exerciseData={exerciseData}
                          textName={props.textName}
                          getQuestion={getQuestion}
                          loading={loading}/>}

          {exerciseStarted && exerciseData.type === 'gg' &&
            <Gg exerciseData={exerciseData}
                textName={props.textName}
                getQuestion={getQuestion}
                loading={loading}/>}

          {exerciseStarted && exerciseData.type === 'translate' &&
            <Translate exerciseData={exerciseData}
                       textName={props.textName}
                       getQuestion={getQuestion}
                       loading={loading}/>}

          {exerciseStarted && exerciseData.type === 'name_from_definition' &&
            <NameFromDefinition exerciseData={exerciseData}
                                textName={props.textName}
                                getQuestion={getQuestion}
                                loading={loading}/>}

          {exerciseStarted && exerciseData.type === 'associate' &&
            <Associate exerciseData={exerciseData}
                       textName={props.textName}
                       getQuestion={getQuestion}
                       loading={loading}/>}

        </div>
      </Col>}

      <Col sm={4}>
        {(exerciseError || props.exerciseError) ? null :
          <Card>
            <div className="me-auto mb-2 pt-4 px-3">
              <h4 className="holodeck_h4 mb-3 px-3">Exercise progress</h4>
              <LineTwo/>
            </div>
            <div className="px-4 pt-4 pb-5 mb-3">

              {(props.exerciseStage === 'ready') ? <ExerciseProgress chunks={chunks}
                                                                     exerciseStarted={exerciseStarted}
                                                                     exerciseData={exerciseData}
                /> :
                <div className="text-center">
                  <img src="icons/task_preloader.svg" height={'14px'} alt="Task preloader"/>
                </div>}
            </div>
          </Card>}
      </Col>
    </Row>

    {exerciseFinished && <div className="pyro fire-body">
      <div className="before"></div>
      <div className="after"></div>
    </div>}
    <div className={'pe-hidden'}>
      <i className="fa fa-spinner fa-spin"/>
    </div>
  </Frame>);
}

export default PersonalizedExercise;