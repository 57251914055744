import React, {useContext} from 'react';
import AuthContext from "../store/auth-context";
import {useHistory} from "react-router-dom";

require('dotenv').config()

function LogoutHooks() {
  const history = useHistory();
  const authCtx = useContext(AuthContext);
  const signOut = (res) => {
    authCtx.logout();
    history.replace("/login")
  };

  return (
    <button onClick={signOut} className="logout-button">
      <span className="buttonText">Sign out</span>
    </button>
  );
}

export default LogoutHooks;
