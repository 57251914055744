import './Layout.css'
import React, {useContext, Fragment, useState} from "react";
import {useLocation} from "react-router-dom";
import Sidebar from "../sidebar/Sidebar";
import LogoutHooks from "../LogoutHooks";
import AuthContext from "../../store/auth-context";


function Layout(props) {
  const authCtx = useContext(AuthContext);
  const location = useLocation();

  let firstName = '';
  if (authCtx.user) {
    firstName = authCtx.user.split(' ')[0];
  }

  const [mainClass, setMainClass] = useState('main')

  function narrowMain() {
    setMainClass('main-narrow');
  }

  function narrowMainBack() {
    setMainClass('main');
  }

  return (
    <Fragment>
      <Sidebar narrowMain={narrowMain} narrowMainBack={narrowMainBack}/>
      <div className={'main-container'}>
        {location.pathname === '/login' ? null :
          <div className="row pl-2">
            <div className="col-2 d-flex flex-row">
            </div>
            <div className="col-10 d-flex flex-row-reverse">
              <div className="px-3"><LogoutHooks/></div>
              {authCtx.isLoggedIn ? <div className="user pt-3">Hi, {firstName}! Nice to see you 🙂</div> : null}

            </div>
          </div>
        }

        {location.pathname !== '/login' ? null :
          <nav className="navbar px-3 pt-3 navbar-light bg-white">
            <a href="https://lingvist.com/">
              <img src="icons/logo.svg" alt="Lingvist"/>
            </a>
          </nav>}
        <main className={mainClass}>{props.children}</main>
      </div>
    </Fragment>
  );
}

export default Layout;
