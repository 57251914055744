import React, {useContext, useState} from 'react';
import {Switch, Route, Redirect, useHistory} from 'react-router-dom';

import Layout from './components/layout/Layout';
import Guess from "./pages/guess/Guess";
import AuthPage from './pages/AuthPage';
import Cloze from "./pages/Cloze";
import GgMainEnFr from "./pages/guess/gg_variants/GgMainEnFr";
import GgPawelSimulateEnFr from "./pages/guess/gg_variants/GgPawelSimulateEnFr";
import GgVariant01EnGe from "./pages/guess/gg_variants/GgVariant01EnGe";
import PersonalizedLearning from "./pages/personalization/PersonalizedLearning";
import DataPipeline from "./pages/data_pipe/DataPipeline";
import Events from "./pages/events/Events";
import HomePage from "./pages/HomePage";
import AuthContext from './store/auth-context';
import {useGoogleLogout} from "react-google-login";


require('dotenv').config()

export const BACKEND_URL = process.env.REACT_APP_API_SERVICE_URL;
const clientId = process.env.REACT_APP_CLIENT_ID;

function App() {
  // If GoogleID is not set, redirect to login page
  const history = useHistory();
  const authCtx = useContext(AuthContext);
  const onLogoutSuccess = (res) => {
    authCtx.logout();
    history.replace("/login")
  };

  const onFailure = () => {
    // console.log('Handle failure cases');
  };

  const {signOut} = useGoogleLogout({
    clientId,
    onLogoutSuccess,
    onFailure,
  });

  if (!authCtx.googleId) {
    signOut();
  }

  // Add Font Awesome icons
  const [stylePath] = useState(
    "https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"
  );

  return (
    <Layout>
      <link rel="stylesheet" type="text/css" href={stylePath}/>
      <Switch>
        {
          <Route path='/login'>
            <AuthPage/>
          </Route>
        }

        <Route path='/guess'>
          {authCtx.isLoggedIn && <Guess/>}
          {!authCtx.isLoggedIn && <Redirect to='/login'/>}
        </Route>

        <Route path='/main-en-fr'>
          {authCtx.isLoggedIn && <GgMainEnFr/>}
          {!authCtx.isLoggedIn && <Redirect to='/login'/>}
        </Route>

        <Route path='/pawel-simulate-en-fr'>
          {authCtx.isLoggedIn && <GgPawelSimulateEnFr/>}
          {!authCtx.isLoggedIn && <Redirect to='/login'/>}
        </Route>

        <Route path='/gg-variant-01-ge-en'>
          {authCtx.isLoggedIn && <GgVariant01EnGe/>}
          {!authCtx.isLoggedIn && <Redirect to='/login'/>}
        </Route>

        <Route path='/cloze'>
          {authCtx.isLoggedIn && <Cloze/>}
          {!authCtx.isLoggedIn && <Redirect to='/login'/>}
        </Route>

        <Route path='/personalized-learning'>
          {authCtx.isLoggedIn && <PersonalizedLearning/>}
          {!authCtx.isLoggedIn && <Redirect to='/login'/>}
        </Route>

        <Route path='/data-pipeline'>
          {authCtx.isLoggedIn && <DataPipeline/>}
          {!authCtx.isLoggedIn && <Redirect to='/login'/>}
        </Route>

        <Route path='/events'>
          {authCtx.isLoggedIn && <Events/>}
          {!authCtx.isLoggedIn && <Redirect to='/login'/>}
        </Route>

        <Route path='/home'>
          {authCtx.isLoggedIn && <HomePage/>}
          {!authCtx.isLoggedIn && <Redirect to='/login'/>}
        </Route>

        {!authCtx.isLoggedIn && (
          <Route path='/cloze'>
            <Cloze/>
          </Route>
        )}

        <Route path='*'>
          <Redirect to='/login'/>
        </Route>

      </Switch>
    </Layout>
  );
}

export default App;