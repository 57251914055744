import './Sidebar.css';
import SideNav, {NavIcon, NavItem, NavText} from "@trendmicro/react-sidenav";
import React, {useState, Fragment} from "react";
import {Link, useLocation} from "react-router-dom";

function Sidebar(props) {
  const location = useLocation();

  function linkStyle(arg) {
    if (location.pathname === arg) {
      return "active-link"
    }
    return "inactive-link"
  }

  const [sidebarDisplay, setSidebarDisplay] = useState('');

  const showSidebar = () => {
    setSidebarDisplay("block");
  }
  const hideSidebar = () => {
    if (window.innerWidth < 801) {
      setSidebarDisplay("none");
    }
  }

  return (
    <Fragment>
      {location.pathname === '/login' ? null :
        <div>
          {/* Menu icon*/}
          <div className={"mobile-menu"} onClick={() => showSidebar()}>
            <img src="icons/mobile_menu.svg" alt="menu"/>
          </div>

          <SideNav style={{display: sidebarDisplay}} onMouseOver={() => props.narrowMain()}
                   onMouseLeave={() => props.narrowMainBack()}>

            {/* Lingvist logo */}
            <SideNav.Toggle componentClass="div" className={"logo"}>
              <a href="https://lingvist.com/">
                <img src="icons/logo_small.svg" alt="Lingvist"/>
                {/*<img src="icons/lingvist.svg" alt="Lingvist"/>*/}
              </a>
            </SideNav.Toggle>

            {/* Sidebar */}
            <SideNav.Nav onClick={() => hideSidebar()}>
              {/*<Link to={'/guess'} className={"inactive-link"}>*/}
              {/*  <NavItem eventKey="guess_game">*/}
              {/*    <NavIcon>*/}
              {/*      <img className={linkStyle("/guess")} src="icons/gg_icon.svg" alt="Guess Game"/>*/}
              {/*    </NavIcon>*/}
              {/*    <NavText>*/}
              {/*      <span className={linkStyle("/guess")}>Guess Game</span>*/}
              {/*    </NavText>*/}
              {/*  </NavItem>*/}
              {/*</Link>*/}

              {/*<Link to={'/cloze'} className={"inactive-link"}>*/}
              {/*  <NavItem eventKey="cloze_task">*/}
              {/*    <NavIcon>*/}
              {/*      <img className={linkStyle("/cloze")} src="icons/cloze_icon.svg" alt="Reading Exercises"/>*/}
              {/*    </NavIcon>*/}
              {/*    <NavText>*/}
              {/*      <span className={linkStyle("/cloze")}>Reading Exercises</span>*/}
              {/*    </NavText>*/}
              {/*  </NavItem>*/}
              {/*</Link>*/}

              {/*<Link to={'/personalized-learning'} className={"inactive-link"}>*/}
              {/*  <NavItem eventKey="personalization">*/}
              {/*    <NavIcon>*/}
              {/*      <img className={linkStyle("/personalized-learning")} src="icons/personalization.svg" alt="Reading Exercises"/>*/}
              {/*    </NavIcon>*/}
              {/*    <NavText>*/}
              {/*      <span className={linkStyle("/personalized-learning")}>Personalized Learning</span>*/}
              {/*    </NavText>*/}
              {/*  </NavItem>*/}
              {/*</Link>*/}

              {/*<Link to={'/data-pipeline'} className={"inactive-link"}>*/}
              {/*  <NavItem eventKey="data_pipeline">*/}
              {/*    <NavIcon>*/}
              {/*      <img className={linkStyle("/data-pipeline")} src="icons/data_pipeline.svg" alt="Data Pipeline"/>*/}
              {/*    </NavIcon>*/}
              {/*    <NavText>*/}
              {/*      <span className={linkStyle("/data-pipeline")}>Data Pipeline</span>*/}
              {/*    </NavText>*/}
              {/*  </NavItem>*/}
              {/*</Link>*/}

              <Link to={'/events'} className={"inactive-link"}>
                <NavItem eventKey="events">
                  <NavIcon>
                    <img className={linkStyle("/events")} src="icons/cloze_icon.svg" alt="Raw Events"/>
                  </NavIcon>
                  <NavText>
                    <span className={linkStyle("/events")}>Raw Events</span>
                  </NavText>
                </NavItem>
              </Link>

            </SideNav.Nav>
          </SideNav>
        </div>
      }
    </Fragment>
  );
}

export default Sidebar;