import React, {Fragment, useEffect, useState} from "react";

function ExerciseProgress(props) {

  const [currentTask, setCurrentTask] = useState('');
  const [taskPosition, setTaskPosition] = useState(0);

  // If new task
  useEffect(() => {

    let currentTaskName = ''
    if (props.exerciseData.task_type) {
      currentTaskName = props.exerciseData.type + ' ' + props.exerciseData.task_type;
    } else {
      currentTaskName = props.exerciseData.type;
    }

    if (currentTaskName === 'keywords') {
      currentTaskName = 'Select words to learn';
    } else if (currentTaskName === 'gg') {
      currentTaskName = 'Vocabulary learning';
    } else if (currentTaskName === 'cloze SHUFFLE') {
      currentTaskName = 'Fill the gap from the shuffled words';
    } else if (currentTaskName === 'cloze VERB') {
      currentTaskName = 'Fill the gap with the correct verb';
    } else if (currentTaskName === 'name_from_definition') {
      currentTaskName = 'Find the word from the definition';
    } else if (currentTaskName === 'translate') {
      currentTaskName = 'Translate the missing part';
    } else if (currentTaskName === 'associate') {
      currentTaskName = 'Word association';
    }

    setCurrentTask(currentTaskName);

    // Iterate props.chunks to find the current task position
    for (let i = 0; i < props.chunks.length; i++) {
      let rowSplit = props.chunks[i][0].split(' ');
      let chunkNo = parseInt(rowSplit[1]);

      if (props.chunks[i][1] === currentTaskName
        && props.exerciseData.chunk_no + 1 === chunkNo) {
        setTaskPosition(i);
        break;
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.exerciseData.task_type, props.exerciseData.type]);

  const TaskRows = () => {
    const diplayedChunks = props.chunks.map((row, index) => {
        let className = '';
        let borderClass = (row[1] === 'Select words to learn') ? ' pe-table-bottom-border' : '';
        let rowSplit = row[0].split(' ');
        let chunkNo = parseInt(rowSplit[1]);

        if ((chunkNo === props.exerciseData.chunk_no + 1 && currentTask === row[1])
          || (props.exerciseData.chunk_no === 'sum' && row[0] === 'Sum')) {
          className = 'pe-current-exercise-name';
        }

        if (index < taskPosition && props.exerciseStarted) {
          className = 'pe-completed-exercise-name';
        }

        return (<tr className={className + borderClass} key={index}>
          <td>{row[0]}</td>
          <td>{row[1]}</td>
        </tr>)
      }
    )
    return (diplayedChunks)
  }

  return (<Fragment>
    <table>
      <thead>
      <tr>
        <td className={'col-md-3'}><span className="pe-column-header">Text Part</span></td>
        <td><span className="pe-column-header">Task Type</span></td>
      </tr>
      </thead>
      <tbody>
      <tr key='line-tr'>
        <td className={'pe-table-bottom-border'}></td>
        <td className={'pe-table-bottom-border'}></td>
      </tr>
      {<TaskRows/>}
      </tbody>
    </table>
  </Fragment>);
}

export default ExerciseProgress;