import classes from './BookItem.module.css'

function BookItem(props) {

  const title = '{"image": "' + props.image +
    '", "cefr": "' + props.cefr +
    '", "title": "' + props.title +
    '", "author": "' + props.author + '"}'

  return (
    <li className={classes.flex_item}>
      <div title={title} className={classes.top}
           onClick={e => props.handleBookItem(e, props.sefrList)}>

        <div title={title} className={classes.image}>
          <img title={title} src={props.image} alt={props.title}/>
        </div>

        <div title={title} className={classes.title}>
          {props.title}
        </div>

        <div title={title} className={classes.author}>
          {props.author}
        </div>

      </div>
    </li>
  );
}

export default BookItem;