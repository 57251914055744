import React, {Fragment, useEffect, useRef, useState} from "react";
import Card from "../../../components/ui/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {Button} from "react-bootstrap";


function NameFromDefinition(props) {
  const [continueButtonFocus, setContinueButtonFocus] = useState(false);
  const [inputClass, setInputClass] = useState('tasks-answer-input');
  const [loading, setLoading] = useState(false);
  const [displayCorrectIcon, setDisplayCorrectIcon] = useState('tasks-overlay-hidden');
  const [inputText, setInputText] = useState('Enter your answer here...');
  const [answer, setAnswer] = useState('');
  const [inputIsDisabled, setInputIsDisabled] = useState(false);
  const [target, setTarget] = useState([]);

  const continueButton = useRef(null);
  const inputRef = useRef(null);

  // Set on first render
  useEffect(() => {
    localStorage.setItem('responseReceived', 'true');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Set after every API response
  useEffect(() => {
    setTarget(props.exerciseData.target);

    //  Set timer to allow the user to see the answer correctness
    const timer = setTimeout(() => {
      continueButton.current.disabled = false;
      setLoading(false);
      inputRef.current.disabled = false;
      inputRef.current.focus();
      setDisplayCorrectIcon('tasks-overlay-hidden');
      setInputClass('tasks-answer-input');
      setInputText('Enter your answer here...');
      setInputIsDisabled(false);
      setContinueButtonFocus(false);
      inputRef.current.value = props.exerciseData.extra_hints;
      localStorage.setItem('newTask', 'false');
    }, 1000);
    return () => clearTimeout(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.exerciseData]);


  function handleContinue() {
    // Handle correct answer
    if (target.includes(inputRef.current.value)) {
      setInputClass('tasks-answer-input-correct');
      setDisplayCorrectIcon('tasks-overlay-visible');
    } else {
      setInputClass('tasks-answer-input-incorrect');
      setInputText('');
    }

    setLoading(true);
    inputRef.current.disabled = true;
    continueButton.current.disabled = true;
    props.getQuestion(props.textName, 'Post answer', inputRef.current.value);
    continueButton.current.disabled = true;
    setContinueButtonFocus(false);
  }

  useEffect(() => {
    const listener = event => {
      if ((event.keyCode === 9 || event.code === "Enter" || event.code === "NumpadEnter") &&
        (localStorage.getItem('responseReceived') === 'true' || localStorage.getItem('responseReceived') === 'gg_false')) {
        event.preventDefault();
        localStorage.setItem('setEnterKeyPressed', '1');
        localStorage.setItem('responseReceived', 'false');
        handleContinue();
      }
    }
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [answer, continueButtonFocus]);

  const answerInputHandling = (event) => {
    setAnswer(event.target.value);
  }

  return (<Fragment>
    <div className={'mb-3'}>
      <Card>
        <div>
          <Row>
            <div>
              <div className={'pe-flex-space-between'}>
                <h3 className="holodeck_h3 px-4 pt-4">Find the word from the definition</h3>
                <div className="px-4 pt-4">
                  <img src="icons/personalization.svg" alt="Text"/>
                </div>
              </div>
            </div>
          </Row>
          <div className="tasks_boarder px-4 pt-1 pb-3">
            Which word in the text is described by the following definition?
          </div>
          <div className="task-text px-4 pt-2">
            <span className="find-the-word-bold">Definition:</span>
            <span> {props.exerciseData.definition}</span>
          </div>
          <div className="task-text px-4 pb-4">
            <span className="find-the-word-bold">Translation:</span>
            <span> {props.exerciseData.definition_translation}</span>
          </div>
        </div>
      </Card>
    </div>

    <div className={'mb-3'}>
      <Card>
        <div className={'tasks_boarder'}>
          <h4 className="holodeck_h4 px-4 pt-4 pb-3">{props.textName}</h4>
        </div>
        <div className="task-text px-4 pb-4 pt-4">
          {props.exerciseData.text}
        </div>
      </Card>
    </div>

    <div className={'mb-3'}>
      <Card>
        <div>
          <div className="px-4 pb-4 pt-3">
            {/*{hintsHTML}*/}
          </div>
        </div>

        <div>
          <div className={displayCorrectIcon}>
            <img src="icons/correct.svg" alt="Correct"/>
          </div>
          <div className="pe_text px-4 pb-5">
            <input name="answerInput"
                   id="textbox"
                   className={inputClass}
                   placeholder={inputText} type="text"
                   autoComplete="off" autoCorrect="off"
                   autoCapitalize="off" spellCheck="false" data-word="afraid" data-lpignore="true" autoFocus
                   onChange={answerInputHandling}
                   disabled={inputIsDisabled}
                   ref={inputRef}/>
          </div>
        </div>

        <Col sm={12}>
          <div className="tasks_bottom pe-flex-end p-4">
            <Button className="pe-start-button cloze_select_outline_dark" variant="dark"
                    onClick={handleContinue} ref={continueButton}>
              {loading ? <i className="fa fa-spinner fa-spin"/> : null}
              {loading ? 'Loading' : 'Continue'}
            </Button>
          </div>
        </Col>
      </Card>
    </div>
  </Fragment>);
}

export default NameFromDefinition;