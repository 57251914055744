import React, {Fragment, useEffect, useRef, useState} from "react";
import './Tasks.css';
import Card from "../../../components/ui/Card";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import {makeId} from "../../../utils/makeId";
import {Button} from "react-bootstrap";


function Keywords(props) {

  const continueButton = useRef(null);

  const [text, setText] = useState('');
  const [continueButtonFocus, setContinueButtonFocus] = useState(false);
  const [initialKeywords, setInitialKeywords] = useState([]);
  const [keywords, setKeywords] = useState([]);
  const [keywordMatches, setkeywordMatches] = useState([]);
  const [displayRemoveAll, setDisplayRemoveAll] = useState(true);

  useEffect(() => {
    setDisplayRemoveAll(true);
    continueButton.current.disabled = false;
  }, [props.exerciseData.chunk_no]);

  useEffect(() => {
    setKeywords(props.exerciseData.keywords);
  }, [props.exerciseData.keywords]);

  const handleSwitchChange = () => {
    setDisplayRemoveAll(false);
    setKeywords([]);
  }

  const handleContinue = () => {
    let payload = {};
    continueButton.current.disabled = true;

    // Collect the keywords from keywords array to comma separated string
    let keywordsString = '';
    keywords.forEach((item, index) => {
      keywordsString += item + ',';
    });
    // Remove the last comma
    keywordsString = keywordsString.slice(0, -1);
    payload['add'] = keywordsString;

    // Check if there are any initial keywords missing from the keywords array
    let removedKeywords = [];
    initialKeywords.forEach((item, index) => {
      if (!keywords.includes(item)) {
        removedKeywords.push(item);
      }
    });
    // Collect the removed keywords from removedKeywords array to comma separated string
    let removedKeywordsString = '';
    removedKeywords.forEach((item, index) => {
      removedKeywordsString += item + ',';
    });
    // Remove the last comma
    removedKeywordsString = removedKeywordsString.slice(0, -1);
    payload['remove'] = removedKeywordsString;

    props.getQuestion(props.textName, 'Post answer', payload);
    // continueButton.current.disabled = true;
    setContinueButtonFocus(false);
  }

  const addKeyword = (word) => {
    word = word.trim();
    word = word.toLowerCase();
    let newKeywordsFromProps = [...keywords, word];
    setKeywords(newKeywordsFromProps);
    setDisplayRemoveAll(true);
  }

  const buildText = () => {
    let splittedText = props.exerciseData.text.split(/\|.*?\|/g);
    return splittedText.map((item, index) => {
      // Replace in item new line with space and new line

      item = item.replace(/(\r\n|\n|\r|\s\n)/gm, "  \n");

      let splittedItem = item.split(' ');

      const handledSplittedItem = splittedItem.map((item, index) => {
        let regexPunctuation = /[!"#$'%&()*+,-./:;<=>?@[\]^_`{|}~]$/;
        let regexNumbers = /[0-9]/g;

        // If the word ends with punctuation and has no numbers add onClick
        if (regexPunctuation.test(item)) {
          let punctuation = item.match(regexPunctuation)[0];
          let word = item.replace(regexPunctuation, '').replace(/(\r\n|\n|\r|\s\n)/gm, '');
          word = word.replace(/(\r\n|\n|\r|\s\n)/gm, '');

          if (keywords.includes(word)) {
            return <span key={makeId(5)} className={'tasks-vocabulary-selected'}>{item} </span>
          }

          if (regexNumbers.test(word)) {
            return item + ' ';
          }

          return <span key={makeId(5)}>
          <span key={makeId(5)} className={'tasks-word'} onClick={() => addKeyword(word)}>{word}</span>
            {punctuation}&nbsp;
          </span>
        }

        // If the rest of the word has no numbers
        if (!regexNumbers.test(item) && item !== '') {
          let kword = item.replace(/(\r\n|\n|\r|\s\n)/gm, '');
          kword = kword.toLowerCase();

          if (keywords.includes(kword)) {
            return <span key={makeId(5)} className={'tasks-vocabulary-selected'}>{item} </span>
          } else {
            return <span key={makeId(5)}><span key={makeId(5)} className={'tasks-word'}
                                               onClick={() => addKeyword(kword)}>{item}</span>
            <span key={makeId(5)}> </span></span>
          }
        }

        item = item.replace(/(\r\n|\n|\r|\s\n)/gm, " ");
        return item + ' ';
      });

      // Set all keyWordMatches to lowercase
      let lowercaseKeyWordMatches = keywordMatches.map((item, index) => {
        return item.toLowerCase();
      });

      // Set initially selected keywords design
      if (keywords.includes(lowercaseKeyWordMatches[index - 1])) {
        return <span key={makeId(5)}>
        <span key={makeId(5)} className={'tasks-vocabulary-selected'}>{keywordMatches[index - 1]}</span>
          {handledSplittedItem}
          </span>
      } else {
        return <span key={makeId(5)}>
        <span key={makeId(5)} className={'tasks-word'}
              onClick={() => addKeyword(keywordMatches[index - 1])}>{keywordMatches[index - 1]}</span>
          {handledSplittedItem}
          </span>
      }

    });
  }

  useEffect(() => {
    setText(buildText(keywords));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keywords]);

  useEffect(() => {
    setInitialKeywords(props.exerciseData.keywords);

    // Find all regex matches from props.exerciseData.text
    let regex = /\|.*?\|/g;
    let tmpMatches = props.exerciseData.text.match(regex);
    let matches = [];
    if (tmpMatches) {
      // iterate matches and remove first and last character
      matches = tmpMatches.map((item) => {
        return item.substring(1, item.length - 1);
      });
    }
    setkeywordMatches(matches);

    // Select only unique keywords
    let uniqueKeywords = [...new Set(matches)];
    let splittedText = props.exerciseData.text.split(/\|.*?\|/g);

    setText(buildText(splittedText, matches, uniqueKeywords));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.exerciseData.text]);

  useEffect(() => {
    if (continueButtonFocus) {
      continueButton.current.focus();
    }
  }, [continueButtonFocus]);

  const crossHandler = (word) => {
    let arr = keywords.filter(item => item !== word);
    setKeywords(arr);
  }

  return (<Fragment>
      <div className={'mb-3'}>
        <Card>
          <div>
            <Row>
              <div>
                <div className={'pe-flex-space-between'}>
                  <h3 className="holodeck_h3 px-4 pt-4">Select words to learn</h3>
                  <div className="px-4 pt-4">
                    <img src="icons/personalization.svg" alt="Text"/>
                  </div>
                </div>
              </div>
            </Row>
            <div className="px-4 pt-1 pb-3">
              Lingvist analysed this text and thinks you still need to learn these words from this text.
              You can add more words to learn or remove words if you know them already.
            </div>
            <div className="px-4 pt-3 pb-2">
              {keywords.map((item, index) => {
                return <span key={index} className={'tasks_selected_words'}>{item}<img className={'tasks-cross'}
                                                                                       onClick={() => crossHandler(item)}
                                                                                       src="icons/cross.svg"
                                                                                       alt="Guess Game"/></span>
              })}
            </div>
            {displayRemoveAll &&
              <div className="px-4 pb-4 pe-flex-end">
              <span className={'tasks-remove-all'} onClick={handleSwitchChange}>
                Remove all
              </span>
              </div>}
          </div>
        </Card>
      </div>

      <div className={'mb-3'}>
        <Card>
          <div className={'tasks_boarder'}>
            <h4 className="holodeck_h4 px-4 pt-4 pb-3">{props.textName}</h4>
          </div>
          <div className="task-text px-4 pb-4 pt-4">
            {text}
          </div>
        </Card>
      </div>

      <Card>
        <Col sm={12}>
          <div className="tasks_bottom_button pe-flex-end p-4">
            <Button className="pe-start-button cloze_select_outline_dark" variant="dark"
                    onClick={handleContinue} ref={continueButton}>
              {props.loading ? <i className="fa fa-spinner fa-spin"/> : null}
              {props.loading ? 'Loading' : 'Continue'}
            </Button>
          </div>
        </Col>
      </Card>
    </Fragment>
  );
}

export default Keywords;