import Card from "../ui/Card";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import {Button} from "react-bootstrap";
import React, {useEffect, useState, useRef} from "react";
import LineTwo from "../ui/LineTwo";

function DifficultySelector(props) {

  const [easyChecked, setEasyChecked] = useState('');
  const [intermediateChecked, setIntermediateChecked] = useState('');
  const [hardChecked, setHardChecked] = useState('');
  const [selectedDiff, setSelectedDiff] = useState('');

  useEffect(() => {
    if (props.checked || props.difficultyLevel === 'easy') {
      setEasyChecked('checked');
      setIntermediateChecked('');
      setHardChecked('');
      setSelectedDiff('easy')
    } else if (props.difficultyLevel === 'intermediate') {
      setEasyChecked('');
      setIntermediateChecked('checked');
      setHardChecked('');
      setSelectedDiff('intermediate')
    } else if (props.difficultyLevel === 'hard') {
      setEasyChecked('');
      setIntermediateChecked('');
      setHardChecked('checked');
      setSelectedDiff('hard')
    }
  }, [props.difficultyLevel, props.checked]);

  const rightDiv = useRef(null);

  useEffect(() => {
    rightDiv.current.style.height = 'auto';

    const lHeight = props.leftDivHeight;
    const rHeight = rightDiv.current.offsetHeight;

    if (lHeight > rHeight) {
      rightDiv.current.style.height = lHeight + 'px';
    } else {
      props.leftDivH(rHeight)
    }
  }, [props]);


  return <Col sm={8}>
    <Card>
      <Row className="p-3" ref={rightDiv}>
        <div className="me-auto">
          <h4 className="holodeck_h4 mb-3">Select the difficulty of the exercise</h4>
          <LineTwo/>
        </div>

        <Row className="cloze_select_row_2">
          <Col xs={3} className="cloze_select_hide"/>
          <Col className="cloze_select_3">
            <form id="difficulty" onSubmit={e => props.handleFormSubmit(e, selectedDiff, props.taskType, '')}
                  className="customRadio customCheckbox mt-5">

              <div>
                <input type="radio" name="difficulty" id="easy" onChange={props.handleDifficOptionChange}
                       checked={easyChecked}/>
                <label htmlFor="easy">

                  <h4 className="holodeck_h4 text-start">
                    <img className="cloze_select_diff_img" src="images/difficulty_easy.png" alt="book"/>
                    <span className="cloze_select_diff_name">Easy</span>
                  </h4>
                  <span className="cloze_select_diff_expl">
                  This exercise will have only a few gaps for you to complete. Every gap also only has one very clear
                  answer and you have hints to help you along.</span>
                </label>
              </div>

              <div>
                <input type="radio" name="difficulty" id="intermediate" onChange={props.handleDifficOptionChange}
                       checked={intermediateChecked}/>
                <label htmlFor="intermediate">

                  <h4 className="holodeck_h4 text-start">
                    <img className="cloze_select_diff_img" src="images/difficulty_intermediate.png" alt="Intermediate"/>
                    <span className="cloze_select_diff_name">Intermediate</span>
                  </h4>
                  <span className="cloze_select_diff_expl">
                  This exercise will have a few more gaps, some of which are a bit tricker to complete.
                    Hints are available should you want them.</span>
                </label>
              </div>

              <div>
                <input type="radio" name="difficulty" id="hard" onChange={props.handleDifficOptionChange}
                       checked={hardChecked}/>
                <label htmlFor="hard">

                  <h4 className="holodeck_h4 text-start">
                    <img className="cloze_select_diff_img" src="images/difficulty_difficult.png" alt="Difficult"/>
                    <span className="cloze_select_diff_name">Difficult</span>
                  </h4>
                  <span className="cloze_select_diff_expl">
                  This exercise will have a lot of gaps to fill in and complete the text.
                    You still have optional hints available to get you through this tough task.</span>
                </label>
              </div>

              <div className="text-end mb-5">
                <Button className="holo_dark_button" variant="dark" type="submit">Next</Button>
              </div>
            </form>
          </Col>
          <Col md={3} className="cloze_select_hide_2"/>
        </Row>
      </Row>
    </Card>
  </Col>;

}

export default DifficultySelector;