import React, {useEffect, useState, useRef} from 'react';
import '../../css/animate.css';
import './ClozeExercise.css'
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';

import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "../../components/ui/Card";
import {Button, Stack} from "react-bootstrap";
import Frame from "../../components/ui/Frame";
import Line from "../../components/ui/Line";
import {shuffle} from "../../utils/shuffleArray";
import {makeId} from "../../utils/makeId";
// import ToggleSwitch from "../../components/ui/ToggleSwitch";
import {BACKEND_URL} from "../../App";


function ClozeExercize(props) {
  useEffect(() => {
    document.body.style.backgroundColor = "#eef0f4";
    document.body.style.overflow = 'auto';
  }, []);

  // Get nth element from array
  const nthElement = (arr, n = 0) => (n > 0 ? arr.slice(n, n + 1) : arr.slice(n))[0];
  const hintsRowRef = useRef(null);

  const textData = props.textData;
  const corrects = JSON.parse(textData.correct);

  const cloze_ex_down_arrow = 'cloze_ex_down_arrow cloze_ex_rotate'
  const cloze_ex_up_arrow = 'cloze_ex_down_arrow'

  const [inputValue, setInputValue] = useState('');
  const [capNo, setCapNo] = useState(0);
  const [inputClass, setInputClass] = useState('cloze_ex_answer_input');
  const [taskIsCompleted, setTaskIsCompleted] = useState(false);
  const [arrowColor, setArrowColor] = useState("icons/big_back_arrow.svg");
  const [hintClass, setHintClass] = useState("cloze_ex_hide");
  // const [diffLevelLabel, setDiffLevelLabel] = useState("");
  const [difficultyLevel, setDifficultyLevel] = useState("");
  const [initialHints, setInitialHints] = useState([]);
  const [finalHints, setFinalHints] = useState([]);
  // const [shuffleChecked, setShuffleChecked] = useState(false);
  const [translation, setTranslation] = useState('');
  const [taskNo, setTaskNo] = useState(0);
  const [loading, setLoading] = useState(false);
  const [slidedDown, setSlidedDown] = useState(false);
  const [sliderValue, setSliderValue] = useState(corrects.length);
  const [tmpSliderValue, setTmpSliderValue] = useState(0);
  const [showSlider, setShowSlider] = useState(true);
  const [splittedInputs, setSplittedInputs] = useState([]);
  const [initialCapsCount, setInitialCapsCount] = useState(null);
  const [displayedCaps, setDisplayedCaps] = useState([]);
  const [answeredCorrectly, setAnsweredCorrectly] = useState([]);
  const [shuffledDisplayedCaps, setShuffledDisplayedCaps] = useState([]);
  const [repeatPressed, setRepeatPressed] = useState(false);

  const createArray = () => {
    // Create new num array within the caps count
    let tmpArr = [...Array(hints.length).keys()];
    // Remove 0
    tmpArr = tmpArr.slice(1, hints.length)
    tmpArr = tmpArr.sort(function (a, b) {
      return a - b
    });

    // Shuffle created array
    let shuffled = [...tmpArr].sort(() => 0.5 - Math.random());
    const newArray = [0].concat(shuffled)

    // Set displayed gaps state
    setShuffledDisplayedCaps(newArray);
    return newArray
  }


  const hintsRow = useRef(null);
  const repeatButtonRef = useRef(null);

  function inputFocus() {
    try {
      document.getElementsByTagName("input")[0].focus();
    } catch (err) {
    }
  }

  useEffect(() => {
    setCapNo(0);
    setTaskIsCompleted(false);
    setInputValue("");
  }, [textData]);

  const td = textData.text_difficulty;

  useEffect(() => {
    // (td === 'hard' ? setDiffLevelLabel('Difficult') : setDiffLevelLabel(td.charAt(0).toUpperCase() + td.slice(1)));
    setDifficultyLevel(td);
  }, [td]);

  // Handle input fields changes and add values to object
  const changeHandler = evt => {
    setInputValue(evt.target.value);
  };

  let finalText = [];
  let keyValue = '0';
  let inputWidth = 5;
  let capLine = '';
  let correctAnswer = '';
  let replacedAnswer = '';
  let hint;

  const renderTooltip = props => (<Tooltip {...props}>{hints[capNo]}</Tooltip>);

  splittedInputs.forEach((text_element, i) => {
    inputWidth = inputValue.length;

    const input = <input id={"input_" + keyValue} style={{width: inputWidth + 1 + "ch"}} autoComplete="off"
                         autoCorrect="off"
                         autoCapitalize="off" spellCheck="false" data-word="afraid" data-lpignore="true"
                         className={inputClass}
                         key={keyValue++} value={inputValue} autoFocus="autoFocus" onChange={changeHandler}/>;

    capLine = <span className="cloze_ex_cap" key={makeId(5)}>______</span>
    correctAnswer = <span className="cloze_ex_correct_answer" key={makeId(5)}>{corrects[i]}</span>
    replacedAnswer = <span className="cloze_ex_replaced_answer" key={makeId(5)}>{corrects[i]}</span>
    hint = <OverlayTrigger key={makeId(5)} placement="top" overlay={renderTooltip}>
      <span id="hint" className={hintClass} key={makeId(5)}>Hint</span></OverlayTrigger>

    // Build task
    finalText.push(text_element.trim());

    // All gaps after current gap
    if (i > capNo && displayedCaps.includes(i)) (finalText.push(capLine));
    if (i > capNo && !displayedCaps.includes(i)) (finalText.push(replacedAnswer));

    // All gaps before current gap
    if (i < capNo && answeredCorrectly.includes(i)) (finalText.push(correctAnswer));
    if (i < capNo && !answeredCorrectly.includes(i)) (finalText.push(replacedAnswer));

    // Current gap
    if (capNo === i && capNo < initialCapsCount - 1) finalText.push('  ', input, '  ');
    if (capNo === i) finalText.push(hint);
  });
  finalText.pop();

  // Handle Any Word task type settings
  let hints = JSON.parse(textData.hints);

  if (textData.task_type === 'any' || textData.task_type === 'shuffle') {
    hints = JSON.parse(textData.correct);
  }

  useEffect(() => {
    // Hide tooltip hints
    if (textData.task_type === 'any' || textData.task_type === 'suffle' || window.innerWidth > 600) {
      try {
        document.getElementById("hint").className = "cloze_ex_hide";
      } catch (err) {
      }
    }
    inputFocus();
    // eslint-disable-next-line
  }, [finalText]);


  // Handle gaps count adjusting slider
  const onSliderChange = value => {
    setSliderValue(value);
    setTmpSliderValue(value);

    // Handle hints
    let tmpHints = shuffledDisplayedCaps.slice(0, value);

    tmpHints = tmpHints.sort(function (a, b) {
      return a - b
    });
    let tmpFinalHints = [];
    tmpHints.forEach(function (item, index) {
      tmpFinalHints.push(nthElement(initialHints, item));
    });

    if (textData.task_type === 'any') {
      shuffle(tmpFinalHints);
    }
    setFinalHints(tmpFinalHints);

    // Set displayed gaps state
    setDisplayedCaps(tmpHints);
  }


  // After task is completed focus on Next Button
  const nextButtonRef = useRef(null);
  useEffect(() => {
    try {
      if (taskIsCompleted) {
        nextButtonRef.current.focus();
      } else {
        try {
          nextButtonRef.current.blur();
        } catch (err) {
        }
        inputFocus();
      }
    } catch (err) {
    }
  }, [taskIsCompleted]);

  // Handle input submit
  function submitHandler(event) {
    try {
      if (event.target.elements) {
        event.preventDefault();
      }
    } catch (err) {
    }

    const input = document.getElementById("input_" + capNo);

    input.value = corrects[capNo];
    input.className = 'cloze_ex_answer_input animate__animated animate__headShake';

    // Handle correct answer
    let correctAnswer = corrects[capNo];
    if (inputValue.toLowerCase() === correctAnswer.toLowerCase()) {
      setInputValue('');
      setShowSlider(false);

      let capNoIndex = displayedCaps.indexOf(capNo);

      if (displayedCaps[capNoIndex + 1] && displayedCaps[capNoIndex + 1] < hints.length) {
        setCapNo(displayedCaps[capNoIndex + 1]);
      }
      // Handle last gap
      else {
        setCapNo(hints.length);
        setTaskIsCompleted(true);
      }

      // Add correct cap to the answered correctly array
      let tmp = [...answeredCorrectly]
      tmp.push(capNo);
      setAnsweredCorrectly(tmp);

      // Highlight hint if correct
      try {
        document.getElementById("side_hint_" + capNo).className = "cloze_ex_side_hint_solved";
      } catch (err) {
      }
      try {
        document.getElementById("side_hint_img_" + capNo).className = "cloze_ex_check";
      } catch (err) {
      }

      //  Handle wrong answer
    } else {
      setInputValue(correctAnswer);
      // input.className = 'cloze_ex_answer_input animate__animated animate__headShake';
      setInputClass('cloze_ex_answer_input animate__animated animate__headShake');
      // input.style.transition = 'all 0ms ease-in';
      input.style.border = 'solid 1px white';
      input.disabled = true;
      input.style.backgroundColor = 'white';
      input.style.color = '#df6c65';
      setHintClass("cloze_ex_hide")

      setTimeout(function () {
        setInputClass('cloze_ex_answer_input');
        setInputValue('');
        // input.style.transition = 'all 100ms ease-in';
        input.style.border = 'solid 1px #c5d5d9';
        input.style.backgroundColor = '#f1f1f1';
        input.style.color = '#037889';
        input.style.height = '1.4em';

        input.disabled = false;
        input.focus();
        input.value = '';

        if ((textData.task_type !== 'any' || textData.task_type !== 'shuffle') && window.innerWidth < 600) {
          try {
            setHintClass("cloze_ex_hint");
          } catch (err) {
          }
        }
      }, 1200);
    }
  }

  function handleSubmitButton(e) {
    if (e.key === 'Tab') {
      e.preventDefault();
      submitHandler();
    }
  }

  // const textDifficulty = "cloze_ex_" + textData.text_difficulty;
  const cloze_ex_footer = 'p-4 mb-5 cloze_ex_footer'
  const cloze_ex_footer_completed = 'p-4 mb-5 cloze_ex_footer_completed'

  // Handle repeat button
  function handleRepeat() {
    setSlidedDown(false);
    setRepeatPressed(true);
    setAnsweredCorrectly([]);
    setShowSlider(true);
    if (textData.source === 'uploaded_by_user') {
      setTaskIsCompleted(false);
      setInputValue("");
      setCapNo(0);
      shuffle(finalHints);

      try {
        for (let i = 0; i < finalHints.length; i++) {
          document.getElementById("side_hint_" + i).className = "cloze_ex_side_hint_normal";
          document.getElementById("side_hint_img_" + i).className = "cloze_ex_hide";
        }
      } catch (err) {
      }
    } else {
      props.getTask(textData.book_name, textData, false, 'exercise', difficultyLevel, textData.task_type, textData.lang_dest);
      setTaskIsCompleted(false);
      setInputValue("");
      setCapNo(0);
      setFinalHints([])
    }
  }

  // Handle next button action
  const handleNext = () => {
    setRepeatPressed(false);
    setTmpSliderValue(0);
    setAnsweredCorrectly([]);
    setShowSlider(true);
    setLoading(true);
    setSlidedDown(false);
    setTaskIsCompleted(false);
    try {
      nextButtonRef.current.blur();
    } catch (err) {
    }
    try {
      nextButtonRef.current.disabled = true;
    } catch (err) {
    }
    try {
      repeatButtonRef.current.style.visibility = 'hidden';
    } catch (err) {
    }

    if (textData.source === 'uploaded_by_user') {
      //  Generate next task
      const worker = new Worker("/task.worker.js", {type: "module"});
      // Send next task to worker
      // Get needed Task from session storage
      const nthTask = (arr, n = 0) => (n > 0 ? arr.slice(n, n + 1) : arr.slice(n))[0];
      const taskText = nthTask(JSON.parse(sessionStorage.splitted_text), taskNo + 2);

      const postMessageData = {'index': taskNo + 2, 'text': taskText};
      worker.postMessage(postMessageData);
      worker.onmessage = function (e) {
        let tasks = JSON.parse(sessionStorage.getItem('tasks'));
        tasks[taskNo + 2] = e.data;
        sessionStorage.setItem('tasks', JSON.stringify(tasks));
      }

      if (taskNo + 1 in JSON.parse(sessionStorage.tasks)) {
        // Get needed Task from session storage immediately
        const taskTextData = JSON.parse(sessionStorage.tasks)[taskNo + 1];
        props.getCreatedTask(taskTextData, taskNo + 1, textData.no_of_text_parts)
        setFinalHints([]);
      } else {
        // Get needed Task from session storage, send it to the server, wait, and get new task
        const nthTask = (arr, n = 0) => (n > 0 ? arr.slice(n, n + 1) : arr.slice(n))[0];
        const taskText = nthTask(JSON.parse(sessionStorage.splitted_text), taskNo + 1);
        props.createTask(taskText, taskNo + 1, true, textData.text_file_name, 'exercise', textData.no_of_text_parts);
        setFinalHints([])
      }
    } else {
      props.getTask(textData.book_name, textData, true, 'exercise', difficultyLevel, textData.task_type, textData.lang_dest);
      setFinalHints([]);
    }
  }

  const onMouseHover = () => {
    setArrowColor("icons/big_back_arrow_hover.svg")
  }

  const onMouseLeave = () => {
    setArrowColor("icons/big_back_arrow.svg")
  }

  // Set hints
  useEffect(() => {
    hintsRowRef.current.style.height = '300px';

    // Set user adjusted gaps count
    let tmpCapsCount = null;
    if (sliderValue > corrects.length) {
      setTmpSliderValue(corrects.length);
      tmpCapsCount = corrects.length;
    } else {
      setTmpSliderValue(sliderValue);
      tmpCapsCount = sliderValue;
    }

    // Create random shuffled array. If repeated, use old one.
    let shuffledArray = []
    if (!repeatPressed) {
      shuffledArray = createArray();
    } else {
      shuffledArray = shuffledDisplayedCaps;
    }

    // Use from created random shuffled array only needed gaps.
    shuffledArray = shuffledArray.slice(0, tmpCapsCount);
    shuffledArray = shuffledArray.sort(function (a, b) {
      return a - b
    });
    setDisplayedCaps(shuffledArray);

    let splInputs = [];
    // Split text
    if (textData.cloze_text.includes('[MASK]')) {
      splInputs = textData.cloze_text.split('[MASK]');
    } else {
      splInputs = textData.cloze_text.split('|_|');
    }

    setSplittedInputs(splInputs);
    setInitialCapsCount(splInputs.length);

    setTaskNo(textData.text_part_number);

    const tmpInitialHints = [];
    const tmpFinalHints = [];


    hints.forEach((hint, i) => {
      const span = <span id={'side_hint_' + i}
                         className={(textData.task_type === 'any' || textData.task_type === 'shuffle') ? "cloze_ex_side_hint_normal" : "cloze_ex_side_hint_normal"}
                         key={makeId(5)}>
        <img id={"side_hint_img_" + i} className="cloze_ex_hide"
             src="icons/checkmark_vector.svg" alt="Checked"/>
        {hint}</span>;
      tmpInitialHints.push(span);
      if (shuffledArray.includes(i)) {
        tmpFinalHints.push(span);
      }
    });

    setInitialHints(tmpInitialHints);
    if (textData.task_type === 'any') {
      shuffle(tmpFinalHints);
    }
    setFinalHints(tmpFinalHints);

    // Hide Any type task hint if there is only one hint
    if (finalHints.length === 1 && textData.task_type !== 'verb') {
      setFinalHints([])
    }

    try {
      const currentLeftCardHeight = document.getElementById("task_left_card").offsetHeight;
      const currentRightUpperRowHeight = document.getElementById("task_right_upper_row").offsetHeight;
      const currentRightLowerRowHeight = document.getElementById("task_right_lower_row").offsetHeight;

      const height = currentLeftCardHeight - currentRightUpperRowHeight - currentRightLowerRowHeight;

      if (window.innerWidth >= 600) {
        if (textData.task_type === 'verb' || textData.task_type === 'aux_verb') {
          hintsRow.current.style.height = height - 53 + 'px'
        }
        if (textData.task_type === 'any' || textData.task_type === 'shuffle') {
          hintsRow.current.style.height = height + 'px'
        }
      } else {
        hintsRow.current.style.height = 'auto'
      }

    } catch (err) {
    }
    setLoading(false);
    try {
      nextButtonRef.current.blur();
    } catch (err) {
    }
    try {
      nextButtonRef.current.disabled = false;
    } catch (err) {
    }
    inputFocus()

    try {
      repeatButtonRef.current.style.visibility = 'visible';
    } catch (err) {
    }
    // eslint-disable-next-line
  }, [props.textData]);

  useEffect(() => {
    inputFocus()
  }, [finalHints]);


  function getTranslation(text, selection) {
    let destinationLanguage = '';
    textData.lang_dest === 'unknown' ? destinationLanguage = props.destLanguage : destinationLanguage = textData.lang_dest

    fetch(BACKEND_URL + '/api/guess_api/get_translation', {
      method: 'POST', body: JSON.stringify({
        text: text, from_lang: textData.from_lang, dest_lang: destinationLanguage
      }), headers: {
        'Content-type': 'application/json; charset=UTF-8'
      },
    })
      .then(function (response) {
        if (response.status !== 200) {
          console.log('Looks like there was a problem. Status Code: ' + response.status);
          return;
        }
        response.json().then(function (data) {

          let range = selection.getRangeAt(selection);      // The range at first selection group
          let rect = range.getBoundingClientRect();         // And convert this to useful data

          if (data) {
            const div = document.createElement('div');   // make box
            div.id = 'div_' + text;
            div.textContent = data;
            div.className = 'cloze_ex_translation_tooltip';

            div.style.top = (rect.top - 45) + 'px';
            div.style.left = rect.left + 'px';
            div.style.height = data.height + 'ch';
            div.style.width = 'auto';

            div.style.maxWidth = document.getElementById("origText").offsetWidth + 'px';

            document.body.appendChild(div);
            setTranslation(data);
          }
        });
      })
      .catch(function (err) {
        console.log('Fetch Error :-S', err);
      });
  }

  useEffect(() => {
    document.onmousedown = () => {
      setTranslation('');
    }
  });

  // Remove the selected translation text area (div)
  useEffect(() => {
    if (!translation) {
      try {
        const elements = document.querySelectorAll(`[id^="div_"]`);
        for (let i = 0; i < elements.length; i++) {
          elements[i].remove();
        }
      } catch (err) {
      }
    }
  }, [translation])


  const handleMouseUp = () => {
    let selection = window.getSelection();
    const text = selection.toString();
    if (text.length > 0) {
      text && getTranslation(text, selection)
    }
  }

  const handleSlideDown = () => {
    slidedDown ? setSlidedDown(false) : setSlidedDown(true);
  }

  return (<Frame>
    <Row className="pb-2">
      <Col sm={12} className="px-4 pb-2">

        <div className="col-12">
            <span className="cloze_select_back_h3" onClick={() => props.dataFromExercize('menu', textData.source)}
                  onMouseOver={onMouseHover}
                  onMouseLeave={onMouseLeave}>
              <img className="cloze_select_link" src={arrowColor} alt="menu"/>
             Book selection</span>
        </div>
      </Col>
    </Row>

    <Row className="mb-4 cloze_select_exercise">
      <Col sm={7} className="mb-3">
        <form id="caps" onSubmit={submitHandler} onKeyDown={handleSubmitButton}>
          <Card>
            <div id="task_left_card">
              {textData.source === 'uploaded_by_user' ? <div className={"pt-4"}/> : <Row className="p-3 mb-2">
                <Col className="cloze_select_book_title pt-1 pb-3 text-start">
                  <h3 className="holodeck_h3">{textData.book_name}</h3>
                  <h6>{textData.author}</h6>
                  <span>Chapter {textData.chapter_number}</span>
                </Col>
                <Col className="text-end pt-3">
                  {/*{textData.source === 'uploaded_by_user' ? <></> :*/}
                  {/*  <span className={textDifficulty}>{diffLevelLabel}</span>}*/}
                </Col>
                <Line/>
              </Row>}


              <div id={'origText'} className="cloze_ex_justify pt-3 pb-5" onMouseUp={handleMouseUp}>
                <div className="row px-2 cloze_ex_target">
                  {finalText}
                </div>
              </div>
            </div>
          </Card>
        </form>
      </Col>

      <Col sm={5}>
        <Card>
          <div className="mt-auto">
            <Row id="task_right_upper_row" className="p-3">
              <Col sm={2} className="me-auto mb-2">
                  <span
                    className={'holodeck_h5'}>Task {textData.text_part_number + 1}/{textData.no_of_text_parts}</span>
              </Col>
              <Col sm={10} className="me-auto mb-2">
                <h6>{((textData.task_type === 'verb' || textData.task_type === 'aux_verb') ? <div>
                      <span>Below are hints to help you. They are the base form (lemma) of the verb.
                      You need conjugate it to the correct form of the verb and enter it into the gap.</span>
                  {/*<br/> <br/><span>You can shuffle these hints to make it more difficult.</span>*/}
                </div> : <div>
                      <span>Below are hints to help you. They are mixed in order. You need to choose the right word
                        and enter it into the gap.</span>
                </div>)}</h6>
              </Col>
              <Line/>
            </Row>

            <Row className="p-3  cloze_ex_right_div" ref={hintsRowRef}>
              <div className="mb-4 cloze_ex_right_div">
                {finalHints}
              </div>
            </Row>

            {(showSlider) ?
              <div>
                <Row className="cloze_ex_slider_row">
                  <Col xs={6}>
                    <span className={"cloze_ex_label"}>Adjust gaps</span>
                  </Col>
                  <Col xs={6} className={slidedDown ? cloze_ex_down_arrow : cloze_ex_up_arrow}
                       onClick={handleSlideDown}>
                    <img height={8} width={19} src="icons/down_arrow.svg" alt="Down"/>
                  </Col>
                </Row>

                {slidedDown ? <Row className="cloze_ex_slider_out">
                  <Col xs={12}>
                    <div className={"pb-3"}>
                      <Slider
                        min={1}
                        max={initialCapsCount - 1}
                        value={tmpSliderValue}
                        onChange={onSliderChange}
                        railStyle={{
                          height: 1,
                          backgroundColor: '#626C91',
                        }}
                        handleStyle={{
                          height: 16,
                          width: 16,
                          marginTop: -8,
                          backgroundColor: "#2C3143",
                          border: 0,
                          opacity: 1
                        }}
                        trackStyle={{
                          background: "none"
                        }}
                      />
                    </div>
                    <span>Remove or add more gaps to answer.</span>
                  </Col>
                </Row> : <Row className="cloze_ex_slider_hidden">
                </Row>}
              </div> : null}


            <Stack id="task_right_lower_row" direction="horizontal"
                   className={taskIsCompleted ? cloze_ex_footer_completed : cloze_ex_footer}>
              {capNo !== 0 ? <Col sm={6} className="text-start">
                {textData.text_part_number + 1 < textData.no_of_text_parts ?
                  <Button ref={repeatButtonRef} className="cloze_ex_outline_dark text-start" variant="outline-dark"
                          onClick={handleRepeat}>Repeat</Button> : <span/>}
              </Col> : <Col sm={6} className="text-start">
              </Col>}

              <div className="ms-auto"/>

              {taskIsCompleted ?
                <Col sm={6} className="text-end">
                  {textData.text_part_number + 1 < textData.no_of_text_parts ?
                    <Button id="next_button" className="cloze_ex_dark" variant="dark"
                            onClick={handleNext} ref={nextButtonRef}>
                      {loading ? <i className="fa fa-spinner fa-spin"/> : null}
                      {loading ? 'Loading' : 'Next Task'}
                    </Button> : <span/>}
                </Col> : null}
            </Stack>
          </div>
        </Card>
      </Col>

    </Row>
  </Frame>);
}

export default ClozeExercize;