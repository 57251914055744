import React, {useState, useEffect, Fragment} from 'react';
import Container from 'react-bootstrap/Container';
import {BACKEND_URL} from "../../../App";
import {useContext} from 'react';
import '../../../css/animate.css';

import AuthContext from "../../../store/auth-context";

function GgVariant01EnGe() {
  document.body.style.backgroundColor = "#eef0f4";
  document.body.style.overflow = 'auto';
  const authCtx = useContext(AuthContext);

  let currentUser = authCtx.user;
  let currentGoogleId = authCtx.googleId;
  if (!currentUser) {
    currentUser = 'no_user_data'
  }

  // Set status variables
  const [loadedWord, setLoadedWord] = useState([]);
  const [ProgressWidth, setProgressWidth] = useState([]);
  const [loadedPhraseTag, setPhraseTag] = useState([]);
  const [loadedContextBeforeInput, setLoadedContextBeforeInput] = useState([]);
  const [loadedContextAfter, setLoadedContextAfter] = useState([]);
  const [loadedFormTranslation, setFormTranslation] = useState([]);
  const [loadedContextTranslation, setContextTranslation] = useState([]);
  const [loadedData, setLoadedData] = useState([]);
  const [responseTime, setResponseTime] = useState([]);
  const [inputClass, setInputClass] = useState('answer-input initial-input');
  const [width, setWidth] = useState(0);

  const user = {'name': currentUser, 'id': currentGoogleId};

  function getItem(event) {
    // Get item from server
    fetch(BACKEND_URL + '/api/guess_api/get_item', {
      method: 'POST', body: JSON.stringify({
        user: user,
        variant: "gg-variant-01"
      }), headers: {
        'Content-type': 'application/json; charset=UTF-8'
      },
    })
      .then(function (response) {
          if (response.status !== 200) {
            console.log('Looks like there was a problem. Status Code: ' + response.status);
            return;
          }
          response.json().then(function (data) {
            setWidth(0);
            setLoadedData(data);

            const d = new Date();
            let time = d.getTime();
            setResponseTime(time);

            //Set progress bar width
            if (event === 'firstLoad') {
              setProgressWidth({width: 2 + "%"});
            } else {
              let progressWidth = ProgressWidth;
              progressWidth = parseInt(progressWidth.width.slice(0, -1)) / 2 + 1;
              setProgressWidth({width: progressWidth * 2 + "%"});
            }
            setInput('');

            // Handle context
            let context = data.context.split('|_|');
            const context_before = context[0];
            const context_after = context[context.length - 1];
            setLoadedContextBeforeInput(context_before);
            setLoadedContextAfter(context_after);

            setPhraseTag(data.phrase_tag);
            setFormTranslation(data.form_translation);
            setContextTranslation(data.context_translation);
            setLoadedWord(data.word);
            setInputClass('answer-input initial-input');
          });
        }
      )
      .catch(function (err) {
        console.log('Fetch Error :-S', err);
      });
  }

// Handle card content
  function submitHandler(event) {
    event.preventDefault();

    const d = new Date();
    let timeNow = d.getTime();
    let respTime = (timeNow - responseTime) / 1000;
    setResponseTime(timeNow);

    fetch(BACKEND_URL + '/api/guess_api/answer', {
      method: 'POST', body: JSON.stringify({
        payload: {
          answer: input,
          word: loadedWord,
          user: user,
          item_content: loadedData,
          response_time: respTime
        },
        variant: "gg-variant-01"
      }), headers: {
        'Content-type': 'application/json; charset=UTF-8'
      },
    })
      .then(function (response) {
        if (response.status !== 200) {
          console.log('Looks like there was a problem. Status Code: ' + response.status);
          return;
        }
        response.json().then(function (data) {
          // Handle correct answer
          if (data.answer_correctness === 1) {
            setInputClass('animate__animated animate__pulse answer-input correct-answer-input');
            setInput(loadedWord);
            setWidth(loadedWord.length * 1.1);
            const timer = setTimeout(() => console.log('Initial timeout!'), 1000);
            clearTimeout(timer);

            setTimeout(
              function () {
                getItem();
              }, 1500);
          } else {

            // Handle wrong answer
            setInputClass('animate__animated animate__headShake answer-input wrong-answer-input');
            setInput(loadedWord);
            setWidth(loadedWord.length * 1.1);

            setTimeout(
              function () {
                setInputClass('answer-input wrong-answer-input');
              }, 1000);

            setTimeout(
              function () {
                setInput('');
                setWidth(0);
                setInputClass('answer-input initial-input');
              }, 1500);
          }
        });
      })
      .catch(function (err) {
        console.log('Fetch Error :-S', err);
      });
  }

  useEffect(() => {
    getItem('firstLoad')
    // eslint-disable-next-line
  }, []);

  // Set auto-scaling input width
  const [input, setInput] = useState('');

  const GrowingInput = () => {
    const changeHandler = evt => {
      setInputClass('answer-input initial-input');
      setWidth(evt.target.value.length * 1.1);
      setInput(evt.target.value);
    };
    return (
      <input name="answerInput" id="textbox" style={{width: width + "ch"}} type="text" autoComplete="off"
             autoCorrect="off"
             autoCapitalize="off" spellCheck="false" data-word="afraid" data-lpignore="true" className={inputClass}
             autoFocus onChange={changeHandler} value={input}/>
    )
  };

  return (
    <Fragment>
      <div>
        <Container>
          <div className="p-4 pb-0 bg-white shadow-sm header-card">
          </div>
          <form onSubmit={submitHandler}>
            <div className="p-4 bg-white shadow-sm main-card">
              <div className="learn-content">
                {loadedContextBeforeInput}
                {<GrowingInput/>}
                {loadedContextAfter}
              </div>
              <div className="row pt-4">
                <div>
            <span className="card-grammar">
              {loadedPhraseTag}
            </span>
                </div>
              </div>
            </div>

            <div className="p-4 shadow-sm footer-card">
              <h3>{loadedFormTranslation}</h3>
              {loadedContextTranslation}
            </div>
          </form>
        </Container>
      </div>
    </Fragment>);
}

export default GgVariant01EnGe;