import PersonilizedExerciseItem from "./PersonilizedExerciseItem";
import './PersonalizedExerciseList.css';
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Card from "../ui/Card";

function PersonalizedExerciseList(props) {
  return (
    <div className="pb-5">
      <Card>
        <div>
          <Col fluid="md" className="personal_learning_top_middle px-3">
            <Row>
              <ul className="personalized_exercise_list">
                {props.exercises.map((exercise) => (
                  <PersonilizedExerciseItem
                    key={exercise.id}
                    id={exercise.id}
                    image={exercise.image}
                    book_name={exercise.book_name}
                    text_name={exercise.text_name}
                    author={exercise.author}
                    text_file_name={exercise.text_file_name}
                    handleUserResponse={props.handleUserResponse}
                  />
                ))}
              </ul>
            </Row>
          </Col>
        </div>
      </Card>
    </div>
  );
}

export default PersonalizedExerciseList;