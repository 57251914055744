import React, {useRef, useState, useEffect} from 'react';
import ToggleSwitch from "../../components/ui/ToggleSwitch";

import {Button, Form} from "react-bootstrap";
import classes from './PersonalizedSettingsModal.module.css';

const PersonalizedSettingsModal = (props) => {
  const saveSettingsButton = useRef(null);
  const [values, setValues] = useState(props.userSettings);
  const [warning, setWarning] = useState('');

  const handleSwitch = (checked, task) => {
    setWarning('');
    const tmpValues = {...values, [task]: checked};
    // Remove user_id from the object
    delete tmpValues.user_id;
    delete tmpValues.user_name;
    // Iterate over all tasks and check if all are false
    const allFalse = Object.keys(tmpValues).every((key) => !tmpValues[key]);
    if (allFalse) {
      setWarning('At least one task must be selected!');
      setTimeout(() => {
          setWarning('');
        }
        , 2000);
      return;
    }

    setValues({...values, [task]: checked});
    saveSettingsButton.current.textContent = "Save Settings";
    saveSettingsButton.current.disabled = false;
    saveSettingsButton.current.style.backgroundColor = "#1c1f23";
    saveSettingsButton.current.style.borderColor = "#1a1e21";
  };

  useEffect(() => {
      saveSettingsButton.current.blur();
      if (props.saveSettingsButtonState !== 0) {
        saveSettingsButton.current.textContent = "Saved!";
        saveSettingsButton.current.disabled = true;
        saveSettingsButton.current.style.backgroundColor = "#28a745";
        saveSettingsButton.current.style.borderColor = "#3cab55";
      }
    }
    , [props.saveSettingsButtonState]);

  return (
    <div>
      <Form id="settingsModal" onSubmit={e => props.onSubmit(e, values, 'store_user_settings')}>
        <div className={classes.backdrop} onClick={props.closeSettings}></div>
        <div className={classes.modalPosition}>
          <div className={classes.modal}>
            <div className={classes.header}>
              <span></span>
              <span>Settings</span>
              <span onClick={props.closeSettings}>
              <img className={classes.cross} width={22} src={'icons/cross.svg'} alt="Close"/>
            </span>
            </div>
            <div className={classes.content}>
              <div className={"pb-4"}>Choose the tasks for the exercise:</div>
              <div className={classes.contentRow}>
                <span>Summary of the text</span>
                <ToggleSwitch small id="summary" checked={values.summary}
                              onChange={checked => handleSwitch(checked, "summary")}/>
              </div>
              <div className={classes.contentRow}>
                <span>Vocabulary learning (word guessing game)</span>
                <ToggleSwitch small id="gg" checked={values.gg}
                              onChange={checked => handleSwitch(checked, "gg")}/>
              </div>
              <div className={classes.contentRow}>
                <span>Translate the missing part</span>
                <ToggleSwitch small id="translate" checked={values.translate}
                              onChange={checked => handleSwitch(checked, "translate")}/>
              </div>
              <div className={classes.contentRow}>
                <span>Find the word from the definition</span>
                <ToggleSwitch small id="definition" checked={values.definition}
                              onChange={checked => handleSwitch(checked, "definition")}/>
              </div>
              <div className={classes.contentRow}>
                <span>Word association task</span>
                <ToggleSwitch small id="associate" checked={values.associate}
                              onChange={checked => handleSwitch(checked, "associate")}/>
              </div>
              <div className={classes.contentRow}>
                <span>Fill the gap from the shuffled words</span>
                <ToggleSwitch small id="shuffle" checked={values.shuffle}
                              onChange={checked => handleSwitch(checked, "shuffle")}/>
              </div>
              <div className={classes.contentRow}>
                <span>Fill the gap with the correct verb</span>
                <ToggleSwitch small id="verb" checked={values.verb}
                              onChange={checked => handleSwitch(checked, "verb")}/>
              </div>
            </div>
            <footer className={classes.actions}>
              <span className={classes.warning}>{warning}</span>
              <Button className={classes.dark_button} variant="dark" type="submit"
                      ref={saveSettingsButton}>Save Settings</Button>
            </footer>
          </div>
        </div>
      </Form>
    </div>
  );
};

export default PersonalizedSettingsModal;
