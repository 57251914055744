import React, {Fragment} from 'react';
import {Link} from "react-router-dom";
import './Guess.css';
import Frame from "../../components/ui/Frame";
import Card from "../../components/ui/Card";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

function Guess() {
  document.body.style.backgroundColor = "#EEF0F4";
  document.body.style.overflow = 'auto';


  return (
    <Fragment>
      <Frame>
        <h3 className="holodeck_h3 px-4 pb-1">GG Variants</h3>
        <Card>
          <Col fluid="md" className="px-3 mb-4">
            <Row className="cloze_menu_top_header py-4">
              <Col className="cloze_menu_fixed_one">
              <span>
                <Link to="/main-en-fr" className={"holo-inactive-link"}>Learn French from English (main)</Link>
              </span>
                <br/>
                <span>
                <Link to="/pawel-simulate-en-fr" className={"holo-inactive-link"}>Learn French from English (pawel-simulate)</Link>
              </span>
                <br/>
                <span>
                <Link to="/gg-variant-01-ge-en" className={"holo-inactive-link"}>Learn English from German (gg-variant-01)</Link>
              </span>
              </Col>
              <Col/>
            </Row>
          </Col>
        </Card>
      </Frame>
    </Fragment>);
}

export default Guess;