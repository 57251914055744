import React, {Fragment, useEffect, useRef, useState} from "react";
import './Tasks.css';
import Card from "../../../components/ui/Card";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import {makeId} from "../../../utils/makeId";
import {Button} from "react-bootstrap";
import {shuffle} from "../../../utils/shuffleArray";


function ClozeShuffle(props) {

  const inputRef = useRef(null);
  const continueButton = useRef(null);

  const [text, setText] = useState('');
  const [inputClass, setInputClass] = useState('tasks-answer-input');
  const [inputText, setInputText] = useState('Enter your answer here...');
  const [capsStates, setCapsStates] = useState([]);
  const [answer, setAnswer] = useState([]);
  const [currentCapNo, setCurrentCapNo] = useState(1);
  const [inputIsDisabled, setInputIsDisabled] = useState(false);
  const [inputFocus, setInputFocus] = useState(false);
  const [hints, setHints] = useState([]);
  const [correct, setCorrect] = useState([]);
  const [origText, setOrigText] = useState('');
  const [continueButtonFocus, setContinueButtonFocus] = useState(false);
  const [displayCorrectIcon, setDisplayCorrectIcon] = useState('tasks-overlay-hidden');
  const [hintsHTML, setHintsHTML] = useState([]);
  const [answeredHints, setAnsweredHints] = useState([]);


  useEffect(() => {
    continueButton.current.disabled = true;
    localStorage.setItem('setEnterKeyPressed', '0');
  }, []);

  useEffect(() => {
    inputRef.current.disabled = false;
    inputRef.current.focus();
    setCorrect(props.exerciseData.correct);
    setOrigText(props.exerciseData.text);
    setCurrentCapNo(1);

    if (props.exerciseData.hints) {
      const tmpHints = shuffle(props.exerciseData.hints);
      setHints(tmpHints);
    }

    // Create capsState array with the same length as the number of hints and fill it with 0s
    const capsTmp = [];
    if (props.exerciseData.hints) {
      for (let i = 0; i < props.exerciseData.hints.length; i++) {
        capsTmp.push(0);
      }
    }
    setCapsStates(capsTmp);
    setAnsweredHints([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.exerciseData.chunk_no]);

  // Set caps outlook
  useEffect(() => {
    if (props.exerciseData.hints) {
      let splittedText = origText.split('|_|');
      let mappedText = splittedText.map((item, index) => {
        let randomOne = makeId(5);

        // Set current cap design
        if (index === currentCapNo) {
          return <span key={randomOne}><span
            className={'tasks-first-gap'}>                </span>{item}</span>
        }

        // Set correctly answered caps design
        else if (capsStates[index - 1] === 1) {
          return <span key={randomOne}><span
            className={'tasks-gap-correct'}>{correct[index - 1]}</span>{item}</span>
        }

        // Set unanswered caps design
        return <span key={randomOne}>
        {index !== 0 && <span
          className={'tasks-gap'}>                </span>}
          {item}</span>
      });
      setText(mappedText);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [capsStates]);

  // Set hints outlook
  useEffect(() => {

    if (props.exerciseData.hints) {

      let mappedHints = hints.map((item, index) => {
        let randomOne = makeId(5);
        if (item === correct[currentCapNo - 2] || answeredHints.includes(index)) {
          let tmpIndex = answeredHints;
          tmpIndex.push(index);
          setAnsweredHints(tmpIndex);

          return <span key={randomOne} className={'tasks-hints-answered'}>{item}</span>
        }
        return <span key={randomOne} className={'tasks-hints'}>{item}</span>
      });
      setHintsHTML(mappedHints);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [capsStates]);

  const answerInputHandling = (event) => {
    setAnswer(event.target.value);
    continueButton.current.disabled = false;
  }

  function handleContinue() {
    inputRef.current.disabled = true;
    props.getQuestion(props.textName, 'Post answer', answer);
    inputRef.current.value = '';
    inputRef.current.placeholder = '';
    inputRef.current.focus();

    setInputClass('tasks-answer-input');
    setDisplayCorrectIcon('tasks-overlay-hidden');
    setInputFocus(true);
    continueButton.current.disabled = true;
    setContinueButtonFocus(false);
  }

  useEffect(() => {
    const listener = event => {
      if ((event.keyCode === 9 || event.code === "Enter" || event.code === "NumpadEnter") && localStorage.getItem('setEnterKeyPressed') === '0') {
        event.preventDefault();

        // If last cap is correct
        if (continueButtonFocus) {
          handleContinue();
          return
        }

        if (event.target.value) {

          // Handle correct answer
          if (event.target.value === correct[currentCapNo - 1]) {

            // Map capsStates array to a new array and change the value of the currentCapNo index to 1
            const capsTmp = capsStates.map((item, index) => {
              if (index === currentCapNo - 1) {
                return 1;
              }
              return item;
            });
            setCapsStates(capsTmp);
            setInputClass('tasks-answer-input-correct');
            setDisplayCorrectIcon('tasks-overlay-visible');
            setCurrentCapNo(currentCapNo + 1);

            // If all caps are not yet answered
            if (currentCapNo !== hints.length) {
              localStorage.setItem('setEnterKeyPressed', '1');
              continueButton.current.disabled = true;
              props.getQuestion(props.textName, 'Post answer', event.target.value);

              const timer = setTimeout(() => {
                setInputText('');
                event.target.value = '';
                setInputClass('tasks-answer-input');
                setDisplayCorrectIcon('tasks-overlay-hidden');
                setInputIsDisabled(false);
                setInputFocus(true);
                localStorage.setItem('setEnterKeyPressed', '0');
              }, 1000);
              return () => clearTimeout(timer);
            } else {
              continueButton.current.disabled = false;
              setInputIsDisabled(true);
              setInputFocus(false);
              setContinueButtonFocus(true);
            }
          }

          // Handle incorrect answer
          else {
            setInputFocus(false);
            setInputClass('tasks-answer-input-incorrect');
            setInputIsDisabled(true);
            inputRef.current.value = correct[currentCapNo - 1]

            const timer = setTimeout(() => {
              setInputText('');
              event.target.value = '';
              setInputClass('tasks-answer-input');
              setInputIsDisabled(false);
              setInputFocus(true);
            }, 1000);
            return () => clearTimeout(timer);
          }
        }
      }
    }
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [answer, continueButtonFocus]);

  useEffect(() => {
    if (inputFocus) {
      inputRef.current.focus();
    }
  }, [inputFocus]);

  useEffect(() => {
    if (continueButtonFocus) {
      continueButton.current.focus();
    }
  }, [continueButtonFocus]);

  return (<Fragment>
    <div className={'mb-3'}>
      <Card>
        <Row>
          <div>
            <div className={'pe-flex-space-between'}>
              <h3 className="holodeck_h3 px-4 pt-4">Fill the gap from the shuffled words</h3>
              <div className="px-4 pt-4">
                <img src="icons/personalization.svg" alt="Text"/>
              </div>
            </div>
          </div>
        </Row>
        <div className="px-4 pt-1 pb-3">
          Type the correct answer for the highlighted gap.
        </div>
      </Card>
    </div>

    <div className={'mb-3'}>
      <Card>
        <div className={'tasks_boarder'}>
          <h4 className="holodeck_h4 px-4 pt-4 pb-3">{props.textName}</h4>
        </div>
        <div className="task-text px-4 pb-5 pt-4">
          {text}
        </div>
      </Card>
    </div>

    <div className={'mb-3'}>
      <Card>
        <div>
          <div className="px-4 pb-4 pt-3">
            {hintsHTML}
          </div>
        </div>

        <div>
          <div className={displayCorrectIcon}>
            <img src="icons/correct.svg" alt="Correct"/>
          </div>
          <div className="pe_text px-4 pb-5">
            <input name="answerInput"
                   id="textbox"
                   className={inputClass}
                   placeholder={inputText} type="text"
                   autoComplete="off" autoCorrect="off"
                   autoCapitalize="off" spellCheck="false" data-word="afraid" data-lpignore="true" autoFocus
                   onChange={answerInputHandling}
                   disabled={inputIsDisabled}
                   ref={inputRef}/>
          </div>
        </div>

        <Col sm={12}>
          <div className="tasks_bottom pe-flex-end p-4">
            <Button className="pe-start-button cloze_select_outline_dark" variant="dark"
                    onClick={handleContinue} ref={continueButton}>
              {props.loading ? <i className="fa fa-spinner fa-spin"/> : null}
              {props.loading ? 'Loading' : 'Continue'}
            </Button>
          </div>
        </Col>
      </Card>
    </div>
  </Fragment>);
}

export default ClozeShuffle;