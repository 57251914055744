import Card from "../ui/Card";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import LineTwo from "../ui/LineTwo";
import {Button} from "react-bootstrap";
import React, {useEffect, useState, useRef} from "react";

function TaskSelector(props) {

  const [verbsChecked, setVerbsChecked] = useState('checked');
  const [anyChecked, setAnyChecked] = useState('');
  const [parallelChecked, setParallelChecked] = useState('');
  const [taskType, setTaskType] = useState('');

  const rightDiv = useRef(null);

  useEffect(() => {

    if (props.checked || props.taskType === 'verb') {
      setVerbsChecked('checked');
      setAnyChecked('');
      setParallelChecked('');
      setTaskType('verb')
    } else if (props.taskType === 'any') {
      setAnyChecked('checked');
      setVerbsChecked('');
      setParallelChecked('');
      setTaskType('any')
    } else if (props.taskType === 'parallel') {
      setParallelChecked('checked');
      setVerbsChecked('');
      setAnyChecked('');
      setTaskType('parallel')
    }
  }, [props.taskType, props.checked]);

  useEffect(() => {
    rightDiv.current.style.height = 'auto';

    const lHeight = props.leftDivHeight;
    const rHeight = rightDiv.current.offsetHeight;

    if (lHeight > rHeight) {
      rightDiv.current.style.height = lHeight + 'px';
    } else {
      props.leftDivH(rHeight)
    }
  }, [props]);


  return <Col sm={8}>
    <Card>
      <Row className="p-3" ref={rightDiv}>
        <div className="me-auto">
          <h4 className="holodeck_h4 mb-3">Select the exercise type</h4>
          <LineTwo/>
        </div>

        <Row className="cloze_select_row">
          <Col xs={3} className="cloze_select_hide"/>
          <Col className="cloze_select_4">
            <form id="task" onSubmit={event => props.handleFormSubmit(event, props.difficultyLevel, taskType, '')}
                  className="customRadio customCheckbox mt-5">
              <Row className="mb-4">
                <Col className="cloze_select_fixed_one_task pb-3 pt-2">
                  <input type="radio" name="tasks" id="verb"
                         onChange={event => props.handleTaskOptionChange(event, 'Fill in the blank: verbs')}
                         checked={verbsChecked}/>
                  <label htmlFor="verb">
                    <img className="cloze_select_diff_img_task" src="images/task_01.png" alt="verb"/>
                  </label>
                </Col>
                <Col>
                  <h4 className="holodeck_h4">Fill in the blank: verbs</h4>
                  <span>Find the right  base form (lemma), conjugate it to the right form and enter
                        it in the gap. Practice verb conjugation, tenses and contextual understanding.</span>
                </Col>
              </Row>

              <Row className="mb-4">
                <Col className="cloze_select_fixed_one_task pb-3 pt-2">
                  <input type="radio" name="tasks" id="any"
                         onChange={event => props.handleTaskOptionChange(event, 'Fill in the blank: any word')}
                         checked={anyChecked}/>
                  <label htmlFor="any">
                    <img className="cloze_select_diff_img_task" src="images/task_02.png" alt="any"/>
                  </label>
                </Col>
                <Col>
                  <h4 className="holodeck_h4">Fill in the blank: any word</h4>
                  <span>Find the right word from hints and type it into the right gap.
                          Practice contextual understanding.</span>
                </Col>
              </Row>

              <Row className="mb-4">
                <Col className="cloze_select_fixed_one_task pb-3 pt-2">
                  <input type="radio" name="tasks" id="parallel"
                         onChange={event => props.handleTaskOptionChange(event, 'Parallel reading')}
                         checked={parallelChecked}/>
                  <label htmlFor="parallel">
                    <img className="cloze_select_diff_img_task" src="images/task_03.png" alt="parallel"/>
                  </label>
                </Col>
                <Col>
                  <h4 className="holodeck_h4">Parallel reading</h4>
                  <span>Read the text in parallel in the language of your choice.</span>
                </Col>
              </Row>

              <div className="text-end mb-5">
                <Button className="holo_dark_button" variant="dark" type="submit">Next</Button>
              </div>

            </form>
          </Col>
          <Col xs={3} className="cloze_select_hide_2"/>
        </Row>
      </Row>
    </Card>
  </Col>;
}

export default TaskSelector;