import React, {Fragment, useEffect, useRef, useState} from "react";
import './Tasks.css';
import Card from "../../../components/ui/Card";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import {Button} from "react-bootstrap";
import '../../../css/animate.css';


function Gg(props) {
  const continueButton = useRef(null);

  const [loadedContextBeforeInput, setLoadedContextBeforeInput] = useState([]);
  const [loadedContextAfter, setLoadedContextAfter] = useState([]);
  const [loadedFormTranslation, setFormTranslation] = useState([]);
  const [loadedContextTranslation, setContextTranslation] = useState([]);
  const [inputClass, setInputClass] = useState('');
  const [width, setWidth] = useState(0);
  const [inputValue, setInputValue] = useState('');
  const [inputIsDisabled, setInputIsDisabled] = useState(false);
  const [correctAnswer, setCorrectAnswer] = useState('');
  const [timer, setTimer] = useState(0);
  const [ggFontSize, setGgFontSize] = useState(30);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    localStorage.setItem('setEnterKeyPressed', '0');

    // Set dynamic font size
    if (props.exerciseData.context.length > 100) {
      setGgFontSize(25);
    } else if (props.exerciseData.context.length <= 100) {
      let tmpFontSize = 25 * 100 / props.exerciseData.context.length;
      if (tmpFontSize > 33 || ggFontSize > 30) {
        tmpFontSize = 33;
      }
      setGgFontSize(tmpFontSize);
    }

    // Handle context
    let context = props.exerciseData.context.split('|_|');
    const context_before = context[0];
    const context_after = context[context.length - 1];
    setLoadedContextBeforeInput(context_before);
    setLoadedContextAfter(context_after);
    setFormTranslation(props.exerciseData.form_translation);
    setContextTranslation(props.exerciseData.context_translation);
    setCorrectAnswer(props.exerciseData.word);
    setInputClass('animate__animated animate__pulse gg-answer-input initial-input');
    localStorage.setItem('setEnterKeyPressed', '0');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timer]);

  useEffect(() => {
    const listener = event => {
      if ((event.keyCode === 9 || event.code === "Enter" || event.code === "NumpadEnter") && localStorage.getItem('setEnterKeyPressed') === '0') {
        localStorage.setItem('setEnterKeyPressed', '1');
        handleInput();
      }
    }
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputValue]);

  const GrowingInput = () => {
    const changeHandler = evt => {
      continueButton.current.disabled = false;
      setInputClass('gg-answer-input initial-input');
      setWidth(evt.target.value.length * 1.1);
      setInputValue(evt.target.value);
    };
    return (<input name="answerInput" id="textbox" style={{width: width + "ch"}} type="text" autoComplete="off"
                   autoCorrect="off" disabled={inputIsDisabled} autoCapitalize="off" spellCheck="false"
                   data-word="afraid" data-lpignore="true" className={inputClass}
                   autoFocus onChange={changeHandler} value={inputValue}/>)
  };

  const handleInput = () => {
    setLoading(true);
    continueButton.current.blur();
    if (continueButton.current.disabled === false) {

      // Handle correct answer
      if (inputValue === correctAnswer && inputValue !== '') {
        continueButton.current.disabled = true;
        setInputIsDisabled(true);
        localStorage.setItem('responseReceived', 'false');
        props.getQuestion(props.textName, 'Post answer', inputValue);
        // setInputClass('animate__animated animate__pulse gg-answer-input correct-answer-input');
        setInputClass('gg-answer-input correct-answer-input');
        setTimeout(() => {
          let timer = setInterval(() => {
            if (localStorage.getItem('responseReceived') === 'true') {
              localStorage.setItem('responseReceived', 'gg_false');

              setInputIsDisabled(false);
              setInputValue('');
              setTimer(timer + 1);
              setInputClass('gg-answer-input initial-input');
              setLoading(false);
              try {
                continueButton.current.disabled = false;
              } catch (e) {
              }
              clearInterval(timer);
            }
          }, 100);
        }, 1500);
      } else {

        // Handle wrong answer
        continueButton.current.disabled = true;
        setInputIsDisabled(true);
        setWidth(correctAnswer.length * 1.1);
        setInputValue(props.exerciseData.word);
        setInputClass('animate__animated animate__headShake gg-answer-input wrong-answer-input');
        setTimeout(() => {
          setInputIsDisabled(false);
          setInputValue('');
          setTimer(timer + 1);
          setInputClass('gg-answer-input initial-input');
          continueButton.current.disabled = false;
          setLoading(false);
        }, 1500);
      }
    }
  }

  const editContextTranslation = () => {
    if (loadedContextTranslation.length > 0) {

      let re = new RegExp(loadedFormTranslation.toLowerCase());
      let position = loadedContextTranslation.toLowerCase().search(re);

      if (position !== -1) {
        let formTranslation = loadedFormTranslation;
        let beforePosition = loadedContextTranslation.substring(0, position);
        let afterPosition = loadedContextTranslation.substring(position + loadedFormTranslation.length, loadedContextTranslation.length);

        beforePosition = beforePosition.replace(/-->/, '');
        afterPosition = afterPosition.replace(/<--/, '');
        afterPosition = afterPosition.replace(' .', '.');

        if (beforePosition.length > 0) {
          formTranslation = formTranslation.toLowerCase();
        }

        return (<span>{beforePosition}<span
          className="gg-context-translation">{formTranslation}</span>{afterPosition}</span>);

      } else {
        return (<span>{loadedContextTranslation}</span>);
      }
    }
  };

  return (<Fragment>
    <div className={'mb-3'}>
      <Card>
        <div>
          <Row>
            <div>
              <div className={'pe-flex-space-between'}>
                <h3 className="holodeck_h3 px-4 pt-4">Vocabulary learning</h3>
                <div className="px-4 pt-4">
                  <img src="icons/personalization.svg" alt="Text"/>
                </div>
              </div>
            </div>
          </Row>
          <div className="px-4 pt-1 pb-4">
            Enter the correct word in the gap. If you dont know it, just press enter.
          </div>
        </div>
      </Card>
    </div>

    <div className={'mb-3'}>
      <Card>
        <div className="py-5 px-4 shadow-sm ">
          {/*Add div with font-size style 10px*/}

          <div className="tasks-learn-content"
               style={{fontSize: `${ggFontSize}px`}}>
            {loadedContextBeforeInput}
            {<GrowingInput/>}
            {loadedContextAfter}
          </div>
        </div>

        <div className="p-4 shadow-sm tasks-footer-card">
          <h3>{loadedFormTranslation}</h3>
          {editContextTranslation()}
        </div>

        <Col sm={12}>
          <div className="tasks_bottom pe-flex-end p-4">
            <Button className="pe-start-button cloze_select_outline_dark" variant="dark"
                    onClick={handleInput} ref={continueButton}>
              {loading ? <span>&nbsp;&nbsp;&nbsp;&nbsp;<i className="fa fa-spinner fa-spin"/></span> : null}
              {loading ? null : 'Continue'}
            </Button>
          </div>
        </Col>
      </Card>
    </div>
  </Fragment>);
}

export default Gg;