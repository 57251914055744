import './AuthPage.css'
import {useContext, useEffect} from "react";
import {useGoogleLogin} from '@react-oauth/google';
import {useHistory} from "react-router-dom";
import AuthContext from "../store/auth-context";
import {refreshTokenSetup} from "../utils/refreshToken";

function AuthPage() {
  useEffect(() => {
    document.body.style.backgroundColor = "#ffffff"
    document.body.style.overflow = 'auto';
  }, []);

  const history = useHistory();
  const authCtx = useContext(AuthContext);

  const login = useGoogleLogin({
    onSuccess: async tokenResponse => {
      fetch("https://www.googleapis.com/oauth2/v3/userinfo", {
        method: 'GET', headers: {
          "Authorization": `Bearer ${tokenResponse.access_token}`
        },
      })
        .then(function (response) {
          response.json().then(function (userData) {
            userData.tokenResponse = tokenResponse;

            const allowedEmails = ["alexjamjam@gmail.com",
              "edjjharris@gmail.com",
              "peter.brezny@gmail.com",
              "cjulet@yahoo.com",
              "virkokask@gmail.com"];

            if ('tokenResponse' in userData) {
              if (userData.email.includes("@lingvist.io") ||
                allowedEmails.includes(userData.email)
              ) {
                authCtx.login(userData.tokenResponse.access_token,
                  userData.given_name + ' ' + userData.family_name,
                  userData.sub);

                history.replace("/home");
                refreshTokenSetup(userData.tokenResponse.expires_in, authCtx);
              } else {
                alert('Sorry, you do not have access to this website!')
              }
            }
          });
        })
        .catch(function (err) {
          console.log('Fetch Error :-S', err);
        });
    }
  });


  return (
    <div>
      <div className="AuthPage">
        <h2>Holodeck</h2>
        <h6>Lingvist Labs experimentation platform</h6>
        <button onClick={login} className="login-button">
          <img src="icons/google.svg" alt="google login" className="icon"/>
          <span className="buttonText">Login with Google</span>
        </button>
      </div>
    </div>
  );
}

export default AuthPage;
